/*!
 * site.scss v1.0.0 
 * Claind - ECOM
 * Author Leonardo Bertelli
 * Adiacent SrL
 * !!! --- Lanciare AGENTE AvailableToPubblicAccess, tutte le volte che li carico su lotus css o js  ---!!!
 */
 
// Option A: Include all of Bootstrap
// 1.Include any default variable overrides here (though functions won't be available)

// 2. Include Bootstrap stylesheets
//@import "../node_modules/bootstrap/scss/bootstrap";

// Option B: Include parts of Bootstrap
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
$primary:       		#3d3d3e;
$primary-dark:  	    #1d1c1d;
$primary-light:	     	#5c5b5d;
$primary-ultralight:	#6f6f70;
$secondary:     		#009b89;
$secondary-dark:    	#008e7b;
$secondary-light:   	#27aa99;

$small-font-size: 			.75rem;
$navbar-dark-color: 		rgba(255,255,255, 1);
$offcanvas-vertical-height: 45vh;
$toast-max-width: 			320px;

// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

// 4. Include any optional Bootstrap CSS as needed
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/offcanvas";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/spinners"; 
@import "../node_modules/bootstrap/scss/toasts"; 
@import "../node_modules/bootstrap/scss/tables"; 
@import "../node_modules/bootstrap/scss/modal";  
@import "../node_modules/bootstrap/scss/carousel";  
@import "../node_modules/bootstrap/scss/accordion";
@import "../node_modules/bootstrap/scss/tooltip";
 
@import "mdc.fab.min.css";
@import "swiper-bundle-10.3.1.min.css";

$all-colors: map-merge-multiple($blues, $indigos, $purples, $pinks, $reds, $oranges, $yellows, $greens, $teals, $cyans);

//This will generate new .text-{color}-{level} utilities for every color and level
$utilities: map-merge(
  $utilities,
  (
    "color": map-merge(
      map-get($utilities, "color"),
      (
        values: map-merge(
          map-get(map-get($utilities, "color"), "values"),
          (
            $all-colors
          ),
        ),
      ),
    ),
  )
);

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../node_modules/bootstrap/scss/utilities/api";

// 6. Optionally include icon font
//@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";

:root {
  @each $name, $value in $grid-breakpoints {
    --bs-breakpoint-#{$name}: #{$value};
  }
}

//
// Custom styles
//
input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

body {
	font-family: Overpass,sans-serif;
}

main {
	a {
		color: $secondary-dark;
		text-decoration: none;
	}
	
	a:hover {
		color: $secondary;
	}
}

.fs-6 {
	font-size: 1.5rem;
}

.fs-7 {
	font-size: 1.75rem;
}

.fs-small {
	font-size: $small-font-size!important;
}

.btn{font-family:Overpass,sans-serif!important;}

.text-primary-dark {color: $primary-dark;}
.text-primary-light {color: $primary-light}
.text-secondary-dark {color: $secondary-dark!important;}
.text-secondary-light {color: $secondary-light}

.nav-link {
    display: block;
    padding: .5rem 1rem;
    text-decoration: none;
	color: $secondary-dark;
}

.bd-input-radio {
	
	input {
		margin-left: 6px;
		margin-right: 2px;
	}
}

.bd-radiobutton {

	td{
		padding-right: 13px!important;
	}

}
.bd-checkbox {

	input[type="checkbox"] {
		height: 13px;
		width: 13px;
		margin-right: 10px;	
	}
}

//Login
#cardbodyLogin {
	
	a {
		color: $secondary-dark!important;
		text-decoration: none;
	}
	
	a:hover {
		color: $secondary;
	}

}

.bd-login {
	padding-top: 0!important;
	height: 100%;
	overflow: hidden;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;
	font-style: normal;
}

.bd-react {
	position: relative;
}
	
.bd--lightest {
	color: #505050;
	background-color: #fff;
}
	
.canvas {
	background: #f4f4f4 50%;
	background-image: none;
	background-size: auto;
	background-size: cover;
	height: 100%;
	position: relative;
	width: 100%;
}

.canvas-icon-xs {
	width: 40px;
}

@include media-breakpoint-up(sm) {
	.canvas--background--img {
		background-image: url(loginR1.jpg);
	}
	
	.canvas-background {
		background: rgba(0,0,0,.5)!important;
	
	}
	
	.canvas-layout {
		ms-grid-columns: 1fr minmax(-webkit-min-content,1280px) 1fr;
		-ms-grid-columns: 1fr minmax(min-content,1280px) 1fr;
		grid-template-columns: 1fr minmax(-webkit-min-content,1280px) 1fr;
		grid-template-columns: 1fr minmax(min-content,1280px) 1fr;
				
	}
		
	.canvas-icon {
		height: 80px;
		margin-right: 16px;
	}
	
	.canvas-copy {
		font-size: 18px;
		font-weight: 300;
		color: #f4f4f4;
	}
	
	.column-width-1 {
		width:140.0px
	}
	
	.column-width-2 {
		width:170.0px
	}
	
	.column-width-3 {
		width:200.0px
	}

	.column-width-4 {
		width:230.0px
	}
	
	.column-width-5 {
		width:250.0px
	}
} 

@include media-breakpoint-up(md) {
	.canvas-copy {
		font-size: 20px;
		font-weight: 300;
		color: #f4f4f4;
	}
}

.canvas-background {
	background: #fff;
	height: 100%;
	-webkit-overflow-scrolling: touch;
	overflow-x: hidden;
	overflow-y: auto;
}

.canvas-layout {
	display: -ms-grid;
	display: grid;
	position: relative;
	-ms-grid-rows: 60px min-content minmax(min-content,1fr) minmax(35px,min-content);
	grid-template-rows: 60px -webkit-min-content minmax(-webkit-min-content,1fr) minmax(35px,-webkit-min-content);
	grid-template-rows: 60px min-content minmax(min-content,1fr) minmax(35px,min-content);
	-ms-grid-columns: 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr;
	min-height: 100%;
	visibility: hidden;
	}

.canvas-layout > :first-child {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}

.canvas-layout--only-grid {

	 .canvas-grid {
		-ms-grid-row: 1;
		-ms-grid-row-span: 4;
		grid-row: 1/5;
	}
}

.canvas-layout-loaded {
	visibility: visible !important;
}

.PwdStrength {
    list-style: none;
	padding-left: 0.5rem;
}

.PwdStrength__rule--failed {
    color: #d7373f;
}

.PwdStrength__rule--passed {
    color: #268e6c;
}

.PwdStrength__rule {
    padding-left: 22px;
    position: relative;
}

.PwdStrength__icon {
    left: 0;
    position: absolute;
    top: 6px;
    width: 8px;
}

.verify-identity {
	background-color: $secondary-dark;
}

//FINE Login
 
/* Loading */
#overlay {
  position: fixed;
  display: none;
  width:100%;
  height:100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
  cursor: pointer;
}
  
#overmoreresults, #overaddtocart, #overaddtocartbp, #overconferma, #overaddstage, #overdelstage, #overrunconfig, #oversaveconfig, 
#overaddcontact, #overdraftcart, #oversendcart, #overresetpsw, #overaddaddress, #overdeletebkmprod, #overdeletehisprod, #overchekout,
#overdeletebkmcli, #overdeletehiscli, #overdeletebkmopp, #overdeletehisopp, #overdeletebkmall, #overdeletehisall, #oversearch, 
#overdeletebkmord, #overdeletehisord, #overdeletebkmoff, #overdeletehisoff, #overaddactivity, #overprintprwOff, #overprintprwOrd,
#overSrcFam, #overSrcMod, #overSrcCat3, #overaddbp,#overupdatecart,#overupdaterowcart, #overindsped, #overindfat, #overshipcost, #overinstall,
#overuploadOrd, #overaddcontactCart, #overaddaddressCart, #overaddaddressCart, #overdeletebkmroute, #overdeletebkmdoc, #overaddroutes, #overindstate
{
	position: absolute;
	display: none;
	margin-right: 5px;
}

.bd-form-label {
	margin-bottom: 0;
}

//bd = bootstrap documentation (classi custom di bootstrap che si trovano negli esempi)
.bd-placeholder-img-lg {
    font-size: 3.5rem;
}

bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.bd-highlight {
    background-color: $primary-light;
    border-top: 1px solid $primary;
	border-bottom: 1px solid $primary;
}

.bd-highlight-sm {
    background-color: rgba(0, 0, 0, 0.05);
    border-top: 1px solid rgba(0, 0, 0, 0.2);
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.bd-mdc-fab{
	background-color: $secondary-dark!important;; 
    position: fixed !important;
    bottom: 15px;
	z-index: 1;
	right: 15px;
}

.bd-mdc-fab-canvas {
	left: 0 !important;
	margin-left: 0;
	border-radius: 0 !important;
	width: 36px !important;
	height: 36px !important;
	border: 1px #d9d9d9 solid;
	background-color: #fdfdfd!important; 
    position: fixed !important;
    z-index: 1;
}

.divHideSM {
	display:none!important;
}

.divDisplaySM {
	
}

/*Fixed top main navigator*/
@include media-breakpoint-up(sm) {
  
	body {
		padding-top: 115px;
	}
	.divPosition {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 1030;
	}

	.mdc-fab{
		right: 80px;
	}
	.divHideSM {
		display:block!important;
	}
	
	.divDisplaySM {
		display:none!important;
	}
}

.breadcrumb {

	margin-bottom: 0.5rem;

}

.offcanvas-body {

	hr {
		margin: .6rem 0;
	}
	
	a {
		color: $gray-900;
		text-decoration: none;
	}

	a:hover {
		color: $secondary-dark;
	}
}

.tp-navbar {

	padding: 0;
	color: $white;
	background-color: $primary-dark;
	font-size: $small-font-size;
	line-height: $line-height-sm;
	
	.navbar-text {
		padding-top: .2rem!important;
		padding-bottom: .2rem!important;
	}
	
	.navbar-nav .nav-link.active {
		color: $secondary-light!important;
		text-decoration: underline;
	}

	.nav-link:hover, .nav-link:focus {
		color: $secondary-light!important;
	}
	
	.navbar-nav-svg {
		width: 1.3rem;
		height: 1.3rem;
	}
	 
	.navbar-nav-svg-person {
		width: 1.5rem;
		height: 1.5rem;
	}
}

.bd-navbar {

	padding: .75rem 0;
	background-color: $primary;
	z-index: 1020;
	
	.navbar-nav .nav-link.active {
		color: $secondary-light!important;
		text-decoration: underline;
	}

	.nav-link:hover, .nav-link:focus {
		color: $secondary-light!important;
		text-decoration: underline;
	}
	.navbar-nav-svg {
		width: 1.2rem;
		height: 1.2rem;
	}
	
} 

.pag-navbar {

	.pagination {
		margin-bottom: 0;
	}
	
	.page-link{
		color: $secondary-dark;
	}
	.page-item.active .page-link {
		background-color: $secondary-dark;
		border-color: $secondary-dark;
	}
}

.bd-card-header {
	padding: .5rem 1rem;
	margin-bottom: 0;
	background-color: rgba(0,0,0,0.0);
	border-bottom: 1px solid rgba(0,0,0,0.125);
}

.bd-cart-badge {
	right: 8px;
	top: -5px;
}

.bd-menu-offcanvas {
	width:360px!important;
}

.bd-cart-offcanvas {
	width:910px!important;
}

.bd-bkm-offcanvas {
	width:630px!important;
}

.bd-cart-link {
	color: $secondary-dark!important;
	text-decoration: none;

}
 
.bd-img-thumbnail {
	width: 50px;
}

@include media-breakpoint-up(sm) {

	.bd-footer {
		margin-top: 2rem !important;
	}

}

.bd-modal-footer {
	border-top: 0!important;
}

.bd-mandatory-field {
  font-size: 0.75rem;
  line-height: 1.3;
}

.section-copyright {
    background-color: rgba(0,0,0,0.05);;
    padding: 15px 0;
    font-size: 12px;
    font-weight: 500;
    color: $gray-600;
}

.text-center {
    text-align: center !important;
}

.offcanvas-profile {
	background-color: $secondary-dark;
	height: 240px;
	width: 450px;
	color: white;
}

.offcanvas-personal-data a {
	color: white !important;
	text-decoration: none;
}

.offcanvas-personal-data a:hover {
	color: white !important;
	text-decoration: underline;
}

.offcanvas-profile-body {
	padding: 0 1rem 1rem 1rem!important;
}

.offcanvas-profile-signout {
	background-color: #fff;
}

.offcanvas-profile-icon {
	fill: currentColor;
	color: inherit;
	font-size: 3.5rem;
}

.container-nav-scroll {

	box-shadow: 0 0.5rem 1rem rgba(0,0,0,.05), inset 0 -1px 0 rgba(0,0,0,.1);
	background-color: $primary;
	border-top: 1px solid #fff;
	padding-right: .5rem;
	padding-left: .5rem;
	margin-left: -1rem;
	margin-right: -1rem;
	
	@include media-breakpoint-up(sm) {
		margin-left: 0;
		margin-right: 0;
	}
	
	.navbar-nav-scroll {
		max-width: 100%;
		height: 2.5rem;
		margin-top: 0;
		overflow: hidden;
	}

	.navbar-nav-scroll .navbar-nav {
	   padding-bottom: 2rem;
	   overflow-x: auto;
	   white-space: nowrap;
	   -webkit-overflow-scrolling: touch;
	}
	
	.navbar-nav {

		.nav-link {
			padding-right: .5rem;
			padding-left: .5rem;
			color: #fff;
		}
		
		.nav-link.active, .nav-link:hover {
			color: $secondary-light;
			background-color: transparent;
			text-decoration: underline;
		}

		.nav-link.active {
			font-weight: 500;
		}
	}
}

.folder-foreground {
  pointer-events: auto;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  overflow: visible;
  max-width: 100%;
  max-height: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 1;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}

.folder-container-root {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  vertical-align: bottom;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.folder-container-lg {
	width: 112px;
	height: 80px;
}

.folder-root {
  display: inline-block;
  width: 118px;
  height: 86px;
  overflow: hidden;
  font-weight: normal;
  font-style: normal;
}

.folder-cover-content {
  position: absolute;
  left: 4px;
  right: 4px;
  bottom: 4px;
  top: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 1;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}

.folder-cover-frame {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  -webkit-box-shadow: 0 1px 3px 2px rgba(1,1,0,.06);
  box-shadow: 0 1px 3px 2px rgba(1,1,0,.06);
  background-color: #ffffff;
  min-width: 32px;
  min-height: 32px;
}

.folder-cover-blank {
  background: #fff;
  display: inline-block;
}

.folder-cover-back { 
  display: block;
  position: absolute;
  left: -3px;
  right: -3px;
  bottom: -4px;
}

.folder-cover-front {
  display: block;
  position: absolute;
  left: -3px;
  right: -3px;
  bottom: -4px;
}

.folder-metadata {
  font-size: 14px;
  margin-bottom: 4px;
  margin-left: 8px;
  margin-right: 8px;
  text-align: left;
  color: #bf5712;
  display: block;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 400;
  position: relative;
}

/* Colore moduli */
.modulo-colors {

	border-top: 2px solid $gray-500;
	border-bottom: 2px solid $gray-500;
	 
	.product {
		background-color: $gray-300;
	}
		
	.support {
		background-color: $cyan-300;
	}
	
	.seriale {
		background-color: $cyan-200;
	}
	
	.document {
		background-color: $purple-300;
	}
	
	.client {
		background-color: $blue-300;
	}
	
	.clientc {
		background-color: $blue-200;
	}
	
	.clienti {
		background-color: $blue-100;
	}
	
	.opportunity {
		background-color: $orange-400;
	}

	.opportunityr {
		background-color: $orange-300;
	}
	
	.opportunityc {
		background-color: $orange-200;
	}
	
	.opportunitya {
		background-color: $orange-100;
	}
	
	.quotation {
		background-color: $yellow-300;
	}

	.order {
		background-color: $teal-300;
	}
	
	h6 {
		margin-bottom: 0;
	}
}

.card-link {
	color: $secondary-dark;
}

.card-link:hover {
	color: $secondary;
}

.bd-nav-link-dropdown {
	color: #fff!important;
}

.bd-nav-dropdown-menu .nav-link:hover {
	color: $primary!important;
} 
 
.bd-nav-dropdown-menu {
	background-color: $secondary-dark!important;
	padding: 0.25rem!important;
	border-radius: 0!important;
}

.bd-nav-dropdown-menu .bd-dropdown-divider {
	margin: 0!important;
}

/* Agenda */
.agenda {
	
	.agenda-link {
		display: block;
		padding: .4rem 0.75rem .25rem 0.75rem;
		text-decoration: none;
		color: #fff;
	}
	
	.agenda-link:hover {
		color: $secondary;
	}
	
	.agenda-dropdown-menu {
		min-width: 230px!important;
		border-radius: 0!important;
		transform: translate3d(0px, 34px, 0px)!important;
	}
	
	.agenda-font-size {
		font-size: 0.75rem !important;
	}
	
	@include media-breakpoint-up(sm) {
	
		.agenda-font-size {
			font-size: 1rem !important;
		}
	
	}
	
	.agenda-header {
	
		.agenda-header-col {
		
		}
		
	}
	
	.agenda-row {
	
		
		border-top: 1px solid;
		border-color: #c9c9c9;
		
		.agenda-row-col {
		
			min-height: 90px;
			--bs-gutter-x: 0.5rem !important;
		
			@include media-breakpoint-up(sm) {
				min-height: 130px;
			}
			 
			.agenda-row-col-day {
				width:100%;
			}
			 
			.agenda-row-col-act {
			
				width:100%;
				height: 18px;
				overflow: hidden;
				font-size: 0.55rem !important;
				background-color: $primary-ultralight;
				
				@include media-breakpoint-up(sm) {
					font-size: 0.75rem !important;
					height: 22px;
				}
			}
			
			.agenda-row-col-click {
				width:100%;
				font-size: 0.55rem !important;
				
				@include media-breakpoint-up(sm) {
					font-size: 0.75rem !important; 
				}
			}
			
		}
		
		.agenda-row-col-cur {
			background-color: $secondary-light;
			border-top: 4px solid $secondary-dark;
		}
		
		.agenda-row-col-prev {
			background-color: #f9f9f9;
			border-top: 4px solid #f9f9f9;
		}
		
		.agenda-row-col-next {
			border-top: 4px solid #fff;
		}
	}
}

.bd-row-header{

	.row {

		.col,.col-1,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-10,.col-11,.col-12 {
			padding-top: .75rem;
			padding-bottom: .75rem;
			padding-left: 0.25rem;
			background-color: #F1F1F1;
			border: 1px solid #9F9F9F; 
		}
		 
	}
}
 
.bd-row{

	.row {

		.col,.col-1,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-10,.col-11,.col-12 {
			padding-top: .75rem;
			padding-bottom: .75rem;
			padding-left: 0.25rem;
			background-color: rgba(39,41,43,0.03);
			border: 1px solid rgba(39,41,43,0.1);
		}
		 
	}
}

.bd-row-header-white{

	.row {

		.col,.col-1,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-10,.col-11,.col-12 {
			padding-top: .75rem;
			padding-bottom: .75rem;
			padding-left: 0.25rem;
			border-bottom: 1px solid #9F9F9F;
			font-weight: bolder;
		}
		 
	}
}

.bd-row-white{

	.row {

		.col,.col-1,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-10,.col-11,.col-12 {
			padding-top: .75rem;
			padding-bottom: .75rem;
			padding-left: 0.25rem;
			border-bottom: 1px solid rgba(39,41,43,0.1);
		}
		 
	}
}

/* Carousel */
/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */
#carouselCaptions {
	.carousel-inner {
		.carousel-item {
			height:122px;
		}			
	}
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) { 

	#carouselCaptions {
		.carousel-inner {
			.carousel-item {
				height:150px;
			}			
		}
	}

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
    
	#carouselCaptions {
		.carousel-inner {
			.carousel-item {
				height:340px;
			}			
		}
	}
	
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

	#carouselCaptions {
		.carousel-inner {
			.item {
				height:420px;
			}			
		}
	}
	
}

#carouselCaptions {

	.carousel-inner {
		.carousel-item img {
			max-height: 100%;  
			max-width: 100%; 
			position: absolute;  
			top: 0;  
			bottom: 0;  
			left: 0;  
			right: 0;  
			margin: auto;
		}		
	}

}

.dijitDialog {

	  background: #fff;
	  border: 1px solid #7eabcd;
	  padding: 0;
	  -webkit-box-shadow: 0 5px 10px #adadad;
	  overflow: hidden;
  
	.dijitDialogTitleBar {
		padding: 5px 6px 3px 6px;
		outline: 0;
		background-color: $primary-light !important;
		color: #fff;
		text-align: end;
	}

}

.iframeUpload {
	width: 100%;
	height: 100%;
}

.pnlDialog {
	height: 250px;
	width: 380px;
	margin: 13px;
}
 
.tableUpload {

	td {
		padding:5px;
	}
}

.bd-modal-dialog {
	max-width: 850px !important;
	min-width: 600px !important;
}

.bd-accordion-button {
	padding: 0.5rem 0.5rem !important;
}

/* Magnifier */
@include media-breakpoint-up(sm) {

/*
Credits:
https://github.com/marcaube/bootstrap-magnify
*/

	.mag {
		margin: 0 auto;
		float: none;
	}
		
	.mag img {
		max-width: 100%;
	}
			
	  

	.magnify {
		position: relative;
		cursor: none
	}

	.magnify-large {
		position: absolute;
		display: none;
		width: 175px;
		height: 175px;

		-webkit-box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.85), 0 0 7px 7px rgba(0, 0, 0, 0.25), inset 0 0 40px 2px rgba(0, 0, 0, 0.25);
		   -moz-box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.85), 0 0 7px 7px rgba(0, 0, 0, 0.25), inset 0 0 40px 2px rgba(0, 0, 0, 0.25);
				box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.85), 0 0 7px 7px rgba(0, 0, 0, 0.25), inset 0 0 40px 2px rgba(0, 0, 0, 0.25);
		
		-webkit-border-radius: 100%;
		   -moz-border-radius: 100%;
				 border-radius: 100%
	}
	
}

/* Preview Extensions*/
.folder-foreground {

	.prvdirectory { background: url(icon_folder.png) center center no-repeat; }
		
	.prvext_generic { background: url(genericfile.svg) center center no-repeat; }
		
	.prvext_msg { background: url(email.svg) center center no-repeat; }
		
	.prvext_bat { background: url(code.svg) center center no-repeat; }
	.prvext_cmd { background: url(code.svg) center center no-repeat; }
	.prvext_css { background: url(code.svg) center center no-repeat; }
	.prvext_html { background: url(code.svg) center center no-repeat; }
	.prvext_htm { background: url(code.svg) center center no-repeat; }
	.prvext_java { background: url(code.svg) center center no-repeat; }
	.prvext_php { background: url(code.svg) center center no-repeat; }
	.prvext_js { background: url(code.svg) center center no-repeat; }
	.prvext_pl { background: url(code.svg) center center no-repeat; }
	.prvext_py { background: url(code.svg) center center no-repeat; }
	.prvext_rb { background: url(code.svg) center center no-repeat; }
	.prvext_rbx { background: url(code.svg) center center no-repeat; }
	.prvext_rhtml { background: url(code.svg) center center no-repeat; }
	.prvext_ruby { background: url(code.svg) center center no-repeat; }
	.prvext_afp { background: url(code.svg) center center no-repeat; }
	.prvext_afpa { background: url(code.svg) center center no-repeat; }
	.prvext_asp { background: url(code.svg) center center no-repeat; }
	.prvext_aspx { background: url(code.svg) center center no-repeat; }
	.prvext_c { background: url(code.svg) center center no-repeat; }
	.prvext_cfm { background: url(code.svg) center center no-repeat; }
	.prvext_cgi { background: url(code.svg) center center no-repeat; }
	.prvext_cpp { background: url(code.svg) center center no-repeat; }
	.prvext_h { background: url(code.svg) center center no-repeat; }
	.prvext_lasso { background: url(code.svg) center center no-repeat; }
	.prvext_vb { background: url(code.svg) center center no-repeat; }
	.prvext_xml { background: url(code.svg) center center no-repeat; }
	.prvext_json { background: url(code.svg) center center no-repeat; }
	
	.prvext_zip { background: url(zip.svg) center center no-repeat; }
	.prvext_7z  { background: url(zip.svg) center center no-repeat; }
	.prvext_rar { background: url(zip.svg) center center no-repeat; }
	.prvext_jar { background: url(zip.svg) center center no-repeat; }
	.prvext_gz  { background: url(zip.svg) center center no-repeat; }
	.prvext_cab { background: url(zip.svg) center center no-repeat; }
	.prvext_arv { background: url(zip.svg) center center no-repeat; }
	.prvext_iso { background: url(zip.svg) center center no-repeat; }
	
	.prvext_odt { background: url(docx.svg) center center no-repeat; }
	.prvext_doc { background: url(docx.svg) center center no-repeat; }
	.prvext_docx { background: url(docx.svg) center center no-repeat; }
	
	.prvext_ppt { background: url(pptx.svg) center center no-repeat; }
	.prvext_pptx{ background: url(pptx.svg) center center no-repeat; }
	.prvext_pps { background: url(pptx.svg) center center no-repeat; }
	.prvext_ppsx{ background: url(pptx.svg) center center no-repeat; }
	
	.prvext_txt { background: url(txt.svg) center center no-repeat; }
	
	.prvext_rtf { background: url(rtf.svg) center center no-repeat; }
	
	.prvext_xls { background: url(xlsx.svg) center center no-repeat; }
	.prvext_xlsx{ background: url(xlsx.svg) center center no-repeat; }
	.prvext_csv { background: url(xlsx.svg) center center no-repeat; }
	.prvext_ods { background: url(xlsx.svg) center center no-repeat; }
	
	.prvext_pdf { background: url(pdf.svg) center center no-repeat; }
	.prvext_p7m { background: url(pdf.svg) center center no-repeat; }
	
	.prvext_bmp { background: url(photo.svg) center center no-repeat; }
	.prvext_pcx { background: url(photo.svg) center center no-repeat; }
	.prvext_tif { background: url(photo.svg) center center no-repeat; }
	.prvext_tiff{ background: url(photo.svg) center center no-repeat; }
	.prvext_gif { background: url(photo.svg) center center no-repeat; }
	.prvext_jpg { background: url(photo.svg) center center no-repeat; }
	.prvext_webp { background: url(photo.svg) center center no-repeat; }
	.prvext_jpeg{ background: url(photo.svg) center center no-repeat; }
	.prvext_png { background: url(photo.svg) center center no-repeat; }
	.prvext_ico { background: url(photo.svg) center center no-repeat; }
	.prvext_svg { background: url(photo.svg) center center no-repeat; }
	
	.prvext_mp3 { background: url(audio.svg) center center no-repeat; }
	.prvext_m4p { background: url(audio.svg) center center no-repeat; }
	.prvext_ogg { background: url(audio.svg) center center no-repeat; }
	.prvext_wav { background: url(audio.svg) center center no-repeat; }
	.prvext_mp4 { background: url(audio.svg) center center no-repeat; }
	.prvext_mid { background: url(audio.svg) center center no-repeat; }
	.prvext_midi{ background: url(audio.svg) center center no-repeat; }
	
	.prvext_3gp { background: url(video.svg) center center no-repeat; }
	.prvext_avi { background: url(video.svg) center center no-repeat; }
	.prvext_mov { background: url(video.svg) center center no-repeat; }
	.prvext_mpg { background: url(video.svg) center center no-repeat; }
	.prvext_mpeg{ background: url(video.svg) center center no-repeat; }
	.prvext_swf { background: url(video.svg) center center no-repeat; }
	.prvext_wmv { background: url(video.svg) center center no-repeat; }
	.prvext_flv { background: url(video.svg) center center no-repeat; }
	.prvext_f4v { background: url(video.svg) center center no-repeat; }
	
}

/*!
 * Adiacent - EXSALES B2C
 * Author Leonardo Bertelli
 */
 
.b2c-dis{pointer-events:none;}
 
.b2c-no-results {
	font-size: 2.5rem;
}
 
@include media-breakpoint-down(xl) {
  
	.b2c-no-results {
		font-size: 1.25rem;
	}

}

#b2caddremovecart
{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	display: none;
}

.b2c-body {
	padding-top:0 !important;
	font-family: Nunito Sans,sans-serif !important;
	font-size: 1.8rem;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.06rem;
	line-height: 1.18;
	text-align: start;
	background-attachment: fixed;
	background-color: rgb(255, 255, 255);
	box-sizing: border-box;
	color: rgb(0, 0, 0);
	margin: 0;
}

.b2c-radiobutton {

	td{
		padding-right: 13px!important;
	}

}

.b2c-input-group-right {
	border-right: 0;
}

.b2c-list-group-item-none {
	border: 0!important;
	background-color: transparent!important;
	padding: 0!important;
}

.b2c-hide-input-group {
	border-right: 1px solid !important;
	border-right-color: #dc3545 !important;
	border-top-right-radius: .25rem !important;
	border-bottom-right-radius: .25rem !important;
}

.popover {
	  position: absolute;
	  top: 0;
	  left: 0;
	  z-index: 1070;
	  display: block;
	  max-width: 276px;
	  font-family: var(--bs-font-sans-serif);
	  font-style: normal;
	  font-weight: 400;
	  line-height: 1.5;
	  text-align: left;
	  text-align: start;
	  text-decoration: none;
	  text-shadow: none;
	  text-transform: none;
	  letter-spacing: normal;
	  word-break: normal;
	  word-spacing: normal;
	  white-space: normal;
	  line-break: auto;
	  font-size: .875rem;
	  word-wrap: break-word;
	  background-color: #fff;
	  background-clip: padding-box;
	  border: 1px solid rgba(0,0,0,.2);
	  border-radius: .3rem;
}

.fade {
	transition: opacity .15s linear;
}

.popover .popover-arrow {
	  position: absolute;
	  display: block;
	  width: 1rem;
	  height: .5rem;
}

.popover .popover-arrow::after, .popover .popover-arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-bottom-color: transparent;
  border-style: solid;
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow, .bs-popover-bottom > .popover-arrow {
  top: calc(-.5rem - 1px);
}

.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow, .bs-popover-top > .popover-arrow {
  bottom: calc(-.5rem - 1px);
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after, .bs-popover-bottom > .popover-arrow::after {
  top: 1px;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: #fff;
}

.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after, .bs-popover-top > .popover-arrow::after {
  bottom: 1px;
  border-width: .5rem .5rem 0;
  border-top-color: #fff;
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::before {
  top: 0;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: rgba(0,0,0,.25);
}

.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before, .bs-popover-top > .popover-arrow::before {
  bottom: 0;
  border-width: .5rem .5rem 0;
  border-top-color: rgba(0,0,0,.25);
}

.popover-body {
  padding: 1rem 1rem;
  color: #212529;
}

#b2coverlay {
  position: fixed;
  display: none;
  width:100%;
  height:100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 2;
  cursor: pointer;
}

.b2c-pagination {

	.page-link {

	  color: #000;
	  
	  a {
		color: #000;
		text-decoration: none;
	  }
	  
	}

	.page-item.disabled .page-link {
	  color: #dee2e6;
	  border-color: #dee2e6;
	}

	.page-item.active .page-link {
	  background-color: #616161;
	  border-color: #616161;
	  color: #fff;
	}

}

.b2c-display-sm {
	
}

/*Fixed top main navigator*/
@include media-breakpoint-down(xl) {
  
	.b2c-display-sm {
		display:none!important;
	}

}

/* 
 * Trigger eventi quando vengono raggiunti i punti di interruzione CSS 
 * In combinazione con javascript e tag <div>
*/
.b2c-css-js-ref * {
    display: none;
}

@include media-breakpoint-up(sm)  {.b2c-css-js-ref-sm {display: block;}} // ≥576px
@include media-breakpoint-up(md)  {.b2c-css-js-ref-md {display: block;}} // ≥768px
@include media-breakpoint-up(lg)  {.b2c-css-js-ref-lg {display: block;}} // ≥992px
@include media-breakpoint-up(xl)  {.b2c-css-js-ref-xl {display: block;}} // ≥1200px
@include media-breakpoint-up(xxl) {.b2c-css-js-ref-xxl{display: block;}} // ≥1400px 
/* Fine trigger */

.b2c-previewColor {
	width: 2.5rem;
	height: 2.5rem;
	border: rgba(0,0,0,0.2) solid 1px;
	border-radius: 100%;
}

.b2c-fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}
	
.b2c-banner-bar {
	
	color: #ffffff;
	display: block;
	transition: display 0.25s ease-in .15s;
			
	.b2c-banner-announce {
		
		text-align: center;
		font-size: 9px;
		width: 100%;
			
		.b2c-banner-message {
			text-align: center;
			height: 2rem;
			line-height: 2rem;
			margin: 0;
			letter-spacing: .1rem;
		}

		i {
			font-size: 1.1rem !important;
		}
	}
		
	@include media-breakpoint-up(xl) {

		.b2c-banner-announce {
			font-size: 12px !important;
		}

	}

}

.b2c-banner-bar-hide {
	display: none !important;
}

.b2c-navbar {

	padding: 0;
	background-color: #fff;
	z-index: 1020;

	a, .nav-link {
		color: #000 !important;
	}
	
	.navbar-toggler {
		border: 0;
		border-radius: 0;
		box-shadow: 0 0 0 0 !important;
		padding: .25rem 0 !important;
	}
	
	.b2c-subnavbar {
	
		margin: 0;
		border-bottom: 1px solid rgba(0,0,0,0.7);
		box-shadow: 0 2px 5px #00000032;
		
		.b2c-brand-div {
			background-color: transparent;
			//border: 2px solid transparent;
			//border-color: #adc65e;
			//border-radius: 100%;
			padding: .25rem;
		}
			
		.b2c-brand-div-nh {
			padding: .25rem;
		}
		
		.navbar-brand {
			margin-right: .15rem !important;
		}
	}

	@include media-breakpoint-up(xl) {
		
		.b2c-subnavbar {
			border-bottom: 0 !important;
			box-shadow: 0 0 0 !important;	 
		}
		
	}
	
	.b2c-collnavbar {
	
		font-weight: 600 !important;
		font-size: 1.25rem !important;
		letter-spacing: 1.4px !important;			
		text-align: center;
					
		.b2c-linknavbar {
			text-decoration: none;
			padding: 0 12px 0 12px;
		}
			
		.b2c-linknavbar-active {
			/*
			text-decoration: underline;
			text-decoration-thickness: 1px;
			text-underline-offset: .4rem;
			*/
		}
			
		.b2c-underline-1, .b2c-underline-2, .b2c-underline-3 {
			  width: 0;
			  height: 2px;
			  background-color: #000;
			  padding-top: 0;
			  margin-left: 12px;
			  margin-right: 12px;
			  transition: width .5s ease;
			  transition-delay: .25s;
		}

		.b2C-dropdown-btn {
			font-size: 1.1rem;
			padding-top: .36rem;
		}
		
		.b2C-dropdown-btn:focus {
			box-shadow: 0 0 0 .25rem rgba(255,255,255,1) !important;
		}
	
		.b2C-dropdown-ul {
		
			background-color: #fff;
			border: rgba(0,0,0,0.2) solid 1px;
			font-size: 1.1rem;
			
			li {
				line-height: 35px;
			}
			
		}
		
		.b2C-dropdown-li-active {
			
			background-color: #000;
			
			a {
				color:#fff !important;
			}
			
		}
		
		.dropdown-item:hover, .dropdown-item:focus {
			
			background-color: #282828cf!important;
			color:#fff !important;
			
		}
		
		.b2c-search-field {
			background: transparent;
			padding-right: 0;
			box-shadow: 0 0 !important;
			border: 0;
			border-bottom: 1px solid #fff;
			color:#fff;
			margin-top: .6rem;
			padding-left: 1.5rem;
			height: 2.2rem;
			width: 130px;
		}

		.b2c-search-field-dark {
			border-bottom: 1px solid #000 !important;
			color:#000 !important;
		}
		
		.b2c-search-field:focus {
			padding-bottom: 1rem;
			padding-top: 1rem;
			padding-left: 1.5rem;
			outline: 0 !important;
		}                            
	
	}
	
	.b2c-cart-count {
		  position: relative;
		  background-color: rgb(0, 0, 0);
		  color: rgb(255, 255, 255);
		  height: 1.15rem;
		  width: 1.15rem;
		  border-radius: 100%;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  font-size: .5rem;
		  top: -.4rem;
		  left: -.4rem;
		  line-height: 1;
	}

}

@include media-breakpoint-up(xl) {

	.b2c-navbar {
		padding: 0 12px;	
	}

}

.b2c-offcanvas-menu, .b2c-product-menu {

	border-left: 0 !important;
			
	a:hover {
		color:#000 !important;
		text-decoration: none !important;
	}
	
	.b2c-btn-close {
		width: .25rem;
		height: .25rem;
		margin-top: 0;
		margin-right: 0;
		opacity: .95;
		margin-bottom: .7rem;
		background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat !important;
	}
	
	.b2c-offcanvas-header {
		background-color: #000;
	}
	
	.b2c-accordion-header {
		cursor: pointer;
	}
	
	.b2c-accordion-collapse {
		border-bottom: 1px solid rgba(0,0,0,0.125);
		border-bottom-right-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
	}
	
	.b2c-accordion-button {
		padding: 1.1rem .1rem;
		font-weight: 600;
		font-size: 1.15rem;
	}
	
	.b2c-accordion-button.collapsed {
	
		border-bottom: 1px solid rgba(0,0,0,0.125);
		border-bottom-right-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
	
	}
	
	.b2c-accordion-button:after {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' fill='none'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' fill='currentColor' d='M1 4.51a.5.5 0 000 1h3.5l.01 3.5a.5.5 0 001-.01V5.5l3.5-.01a.5.5 0 00-.01-1H5.5L5.49.99a.5.5 0 00-1 .01v3.5l-3.5.01H1z'/%3e%3c/svg%3e") !important;
	}
	
	.accordion-button:not(.collapsed)::after {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 2' fill='none'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' fill='currentColor' d='M.5 1C.5.7.7.5 1 .5h8a.5.5 0 110 1H1A.5.5 0 01.5 1z'/%3e%3c/svg%3e") !important;
		background-position-y: 8px;
	}

	.b2c-accordion-button:not(.collapsed) {
		color: #000;
		background-color: #fff;
		box-shadow: none;
	}

	.b2c-accordion-list {
	
		padding: .75rem 0 .75rem 1rem !important;
		font-weight: 600;
		font-size: 1.15rem;
		
	}
	
	.b2c-accordion-list-two {
		
		padding: .75rem 0 .75rem 1.8rem !important;
		font-size: 1.1rem;
						
	}
	
	.b2c-menu-footer {
	
		padding: 2.75rem 0 2.75rem 0 !important;
		font-weight: 600;
		font-size: 1.075rem;
	
		.b2c-menu-lingua, .b2c-menu-catalog {
			border-bottom: 1px solid rgba(0,0,0,0.125);
			padding: 1rem .1rem;
		}
		
		.b2c-menu-lingua {
		
			.b2C-dropdown-btn {
				font-size: 1rem;
				padding: .325rem 0 0 0 !important;
				line-height: 0;
			}
			
			.b2C-dropdown-btn:focus {
				box-shadow: 0 0 0 .25rem rgba(255,255,255,1) !important;
			}
		
			.b2C-dropdown-ul {
				background-color: #fff;
				border: rgba(0,0,0,0.2) solid 1px;
				font-size: 1.1rem;
			}
			
			.b2C-dropdown-li-active {
				
				background-color: #000;
				
				a {
					color:#fff !important;
				}
				
			}
			
			.dropdown-item:hover, .dropdown-item:focus {
				
				background-color: #282828cf!important;
				color:#fff !important;
				
			}
			
		}
		
	}
}
 
.b2c-offcanvas-search {
	
	height: 14vh !important;
	
	.b2c-offcanvas-header {
		padding: .5rem 1rem !important;
	}
	
	.b2c-input {
			
		width: 100%;
			
		label {
			font-size: 1.3rem;
			text-align: center;
		}
				
		input {
			border-radius: 0;
			border: 2px solid black;
			box-shadow: 0 0 !important;
			outline: none !important;
			border-right: 0 !important;
		}
					
		.b2c-input-group-text {
			background-color: #fff;
			border-top: 1px solid;
			border-radius: 0;
			border: 2px solid #000;
			border-left: 0 !important;
		}
					
		.b2c-form-floating {
			width: 80%;
		}
			
	}
	
	.b2c-btn-close {
		width: .25rem;
		height: .25rem;
		margin-top: 0;
		margin-right: 0;
		opacity: .75;
	}
	
}
 
.b2c-header-blank {

	border-bottom: 1px solid rgba(0,0,0,.7);
	
	a, .nav-link, .navbar-toggler {
		color: #000 !important;
	}
	
	.b2c-collnavbar {
	
		.b2C-dropdown-btn {
			color: #000;
		}
		
	}
}

.b2c-header-transparent {

	background-color: rgba(241,241,241,0);
	
	a, .nav-link {
		color: #fff !important;
	}
	
	.b2c-collnavbar {
	
		.b2C-dropdown-btn {
			color: #fff;
		}
		
	}
}

.b2c-header-transparent-dark {

	background-color: rgba(241,241,241,0);
	
	a, .nav-link {
		color: #000 !important;
	}
	
	.b2c-collnavbar {
	
		.b2C-dropdown-btn {
			color: #000;
		}
		
	}
}

.b2c-megamenu {

	background-color: #fff;
	font-weight: 600;
	font-size: 1.1rem;
	line-height: 23.4px;
	letter-spacing: 1.4px;		
	margin: auto;
	width:100%;
	top: 100%;
	left: 0;
	right: 0;
	overflow: scroll;
	position: absolute;	
	text-align: left;
	padding: 0 17px 0 17px;
	
	.b2c-menu-lev1 {
	
		font-size: .9rem;
		font-weight: 300;
		padding: 2.2rem 0 2.2rem 0;
		
		.b2c-a-lev1 {
			text-decoration: underline !important;
			text-underline-offset: 3px !important;
		}
		
		.b2c-a-lev1:hover {
			text-decoration-thickness: 2px !important;
		}
	}
	
	a {
		text-decoration: none;
	}
}
	
.b2c-details {
		
	.b2c-megamenu-oc {
		transition:	max-height 0.3s ease-out;
		z-index: -1;
		pointer-events: none;
		max-height: 0;
		overflow: hidden;
	}
	
	.b2c-megamenu-oc-border {
		transition: 
			border-top 0s ease,
			box-shadow 0s ease;
		border-bottom: 1px solid rgba(0,0,0,0.7);
		box-shadow: 0 2px 5px #00000032;
	}
	
}

.b2c-details:hover, .b2c-details:focus {

	.b2c-underline-1, .b2c-underline-2, .b2c-underline-3 {
		width: calc(100% - 24px);
	}

	.b2c-megamenu-oc {
		transition: 
			border-top 0s ease,
			box-shadow 0s ease,
			max-height .9s ease-in .15s;
		z-index: 99;
		pointer-events: auto !important;
		max-height: 3000px;
		overflow: hidden;
		border-top: rgba(0,0,0,0.2) solid 1px !important;
		box-shadow: 0 2px 5px #00000032 !important;
	}
	
}
	
.b2c-whoweare {
	
	display: flex;
	flex-direction: column;
	justify-content: center;
  
	.b2c-whoweare-box {
	
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		height: 690px;
		
		.b2c-whoweare-subtitle {
			margin: 0 auto 1.3rem;
			text-transform: uppercase;
			font-size: 0.9rem;
		}
		
		.b2c-whoweare-title {
			margin: auto;
			text-transform: uppercase;
			font-size: 3.2rem;
			font-weight: 400;
		}
		
	}
	
	.b2c-our-history {
		font-size: 1.05rem;
		padding-top: 21px !important;
		padding-bottom: 21px !important;
	}
	
	@include media-breakpoint-up(xl) {
	
		.b2c-our-history {
			
			padding-top: 28px !important;
			padding-bottom: 28px !important;
			padding: 0;
			padding-top: 0px;
			padding-bottom: 0px;
			
			.b2c-our-history-content {
				width: 43rem;
			}
	 
		}
	
	}
	
	@include media-breakpoint-down(xl) {
	
		.b2c-our-history {
					
			margin: 0 auto !important;
			padding-left: 1.5rem !important;
			padding-right: 1.5rem !important;
	  
		}
	
	}
	
	.b2c-photomgm {
	
		background: #A7B1B6;
		padding: 80px 0;
		position: relative;
		z-index: 3;
		
		.b2c-inner-item {
		
			background: #a7b1b6; 
			-webkit-transition: all .3s ease; 
			transition: all .3s ease; 
			opacity:1; 
	
			h4 { 
			
				display: block; 
				line-height: 1; 
				border-bottom: 1px dotted rgba(0, 0, 0, 0.17); 
				font-size: 13px; 
				margin: 0; 
				padding: 5px; 
				position: relative;
				font-weight: 600;
					
			}
			
			.b2c-img-item {
				position: relative;
				z-index: 0;
			}
			
			.b2c-img-item::after {
			
				content: "";
				position: absolute;
				bottom: -30px;
				width: 101.3%;
				left: -1.6px;
				height: 65px;
				background: #a7b1b6;
				transform: rotate(8deg) skewX(8deg);
				transition: .3s;
			
			}
			
			.b2c-img-item img {
			
				-webkit-filter: grayscale(100%);
				filter: grayscale(60%);
				-webkit-transition: filter 1s;
				transition: filter 1s;
				padding-bottom: 15px;
				margin: 0;
				width: 100%;
				max-width: 100%;
				height: auto;
				border-style: none;
			
			}
		
			.b2c-title {
				
				span.b2c-name {
				
					display:block;
					padding-bottom: 5px;
					color:#2f373b;
					text-transform: uppercase; 
	
				}
				
			}
			
			.b2c-user-desc {
				
				font-size: 12px; 
				color: #777; 
				padding: 10px 5px; 
				margin: 0; 
				display: block; 
				min-height: 115px;
				
				.b2c-settore {
				
					display:block;
					color:#fff;
					font-size:12px;
					border-bottom: 1px solid #EAF4F9;
					padding: 3px 0;
					line-height: 1.4;
					transition: .3s;

				}

				.b2c-settore a:hover, .b2c-settore a:active, .b2c-settore a:focus{
					color:#000;
					text-decoration:none;
				}

				.b2c-settore a{
				
					color:#000;
					text-decoration:underline;

				}

			}
			
		}
		
		.b2c-inner-item:hover {
		
			background: #fff;
			box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.25); 
			opacity:1; 
			
			.b2c-img-item img {
			
				-webkit-filter: grayscale(0%);
				filter: grayscale(0%);
				-webkit-transition: filter 1s;
				transition: filter 1s;
			
			}
		
			.b2c-img-item::after {
				bottom: -20px !important;
				background: #fff;
				transition: .3s;
			}
			
			.b2c-user-desc {
			
				.b2c-settore {
				
					color:#788287;
					transition: .3s;

				}

			}
			
		}
		
	}
	
	.b2c-photomgm:before {
	
		content: '';
		position: absolute;
		top: -8px;
		left: 0;
		height: 80px;
		width: 100%;
		-webkit-transform-origin: 100% 0;
		-ms-transform-origin: 100% 0;
		transform-origin: 100% 0;
		-webkit-transform: skewY(.5deg);
		transform: skewY(.5deg);
		-ms-transform: skewY(.5deg);
		transform: skewY(.5deg);
		-webkit-backface-visibility: hidden;
		z-index: 2;
		background: -moz-linear-gradient(bottom, rgba(255,255,255,0.6) 0%, rgb(255,255,255) 60%);
		background: -webkit-gradient(linear, left bottom, left top, color-stop(0%,rgba(255,255,255,0.6)), color-stop(60%,rgb(255,255,255)));
		background: -webkit-linear-gradient(bottom, rgba(255,255,255,0.6) 0%,rgb(255,255,255) 60%);
		background: -o-linear-gradient(bottom, rgba(255,255,255,0.6) 0%,rgb(255,255,255) 60%);
		background: -ms-linear-gradient(bottom, rgba(255,255,255,0.6) 0%,rgb(255,255,255) 60%);
		background: linear-gradient(to top, rgba(255, 255, 255,.6) 0%,rgb(255,255,255) 60%);
		
	}
	
	.b2c-photomgm:after {
	
		content: '';
		position: absolute;
		bottom: -30px;
		left: 0;
		height: 80px;
		width: 100%;
		-webkit-transform-origin: 100% 0;
		-ms-transform-origin: 100% 0;
		transform-origin: 100% 0;
		-webkit-transform: skewY(.5deg);
		transform: skewY(.5deg);
		-ms-transform: skewY(.5deg);
		transform: skewY(.5deg);
		-webkit-backface-visibility: hidden;
		z-index: 2;
		background: -moz-linear-gradient(top, rgba(255,255,255,0.6) 0%, rgb(255,255,255) 60%);
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0.6)), color-stop(60%,rgb(255,255,255)));
		background: -webkit-linear-gradient(top, rgba(255,255,255,0.6) 0%,rgb(255,255,255) 60%);
		background: -o-linear-gradient(top, rgba(255,255,255,0.6) 0%,rgb(255,255,255) 60%);
		background: -ms-linear-gradient(top, rgba(255,255,255,0.6) 0%,rgb(255,255,255) 60%);
		background: linear-gradient(to bottom, rgba(255, 255, 255,.6) 0%,rgb(255,255,255) 60%);
	
	}
	
	.b2c-phototeam {
	
		background: #fff;
		padding: 80px 0;
		position: relative;
		z-index: 3;
		
		.b2c-inner-item {
		
			background: #fff; 
			-webkit-transition: all .3s ease; 
			transition: all .3s ease; 
			opacity:1; 
	
			h4 { 
			
				display: block; 
				line-height: 1; 
				border-bottom: 1px dotted rgba(0, 0, 0, 0.17); 
				font-size: 13px; 
				margin: 0; 
				padding: 5px; 
				position: relative;
				font-weight: 600;
					
			}
			
			.b2c-img-item {
				position: relative;
				z-index: 0;
			}
			
			.b2c-img-item::after {
			
				content: "";
				position: absolute;
				bottom: -30px;
				width: 101.3%;
				left: -1.6px;
				height: 65px;
				background: #fff;
				transform: rotate(8deg) skewX(8deg);
				transition: .3s;
			
			}
			
			.b2c-img-item img {
			
				-webkit-filter: grayscale(100%);
				filter: grayscale(60%);
				-webkit-transition: filter 1s;
				transition: filter 1s;
				padding-bottom: 15px;
				margin: 0;
				width: 100%;
				max-width: 100%;
				height: auto;
				border-style: none;
			
			}
		
			.b2c-title {
				
				span.b2c-name {
				
					display:block;
					padding-bottom: 5px;
					color:#2f373b;
					text-transform: uppercase; 
	
				}
				
			}
			
			.b2c-user-desc {
				
				font-size: 12px; 
				color: #777; 
				padding: 10px 5px; 
				margin: 0; 
				display: block; 
				min-height: 115px;
				
				.b2c-settore {
				
					display:block;
					color:#000;
					font-size:12px;
					border-bottom: 1px solid #EAF4F9;
					padding: 3px 0;
					line-height: 1.4;
					transition: .3s;

				}

				.b2c-settore a:hover, .b2c-settore a:active, .b2c-settore a:focus{
					color:#000;
					text-decoration:none;
				}

				.b2c-settore a{
				
					color:#000;
					text-decoration:underline;

				}

			}
			
		}
		
		.b2c-inner-item:hover {
		
			background: #fff;
			box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.25); 
			opacity:1; 
			
			.b2c-img-item img {
			
				-webkit-filter: grayscale(0%);
				filter: grayscale(0%);
				-webkit-transition: filter 1s;
				transition: filter 1s;
			
			}
		
			.b2c-img-item::after {
				bottom: -20px !important;
				background: #fff;
				transition: .3s;
			}
			
			.b2c-user-desc {
			
				.b2c-settore {
				
					color:#788287;
					transition: .3s;

				}

			}
			
		}
	
	}
	
}

.b2c-video {

	min-width: 0;
	min-height: 100vh;
	max-width: 100%;
	margin-bottom: 0;
	padding: 0;
  
	.b2c-video-background {
		  background: #FFF;		  
	}
	
	.b2c-video-background-mobile {
		display: -webkit-box;
		display: flex;
		background: #fff;
		-webkit-box-align: center;
		align-items: center;
		position: relative;
		width: 100%;
		height: 100vh;
		margin-bottom: 0;	  
	}
	
	.b2c-video-foreground {
	
		position: relative;
		pointer-events: none;
		overflow: hidden;
		padding-bottom: 56.25%; /* 16:9 */
		height: 0;

		iframe {
		  position: absolute;
		  pointer-events: none;
		  overflow: hidden;
		  top: 0;
		  left: 0;
		  width: 100%;
		  height: 100%;
		  margin-top: -60px;
		}
		
	}
	
	.b2c-overlay-video {
			  
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		-webkit-transition: opacity .5s linear;
		transition: opacity .3s linear;
		-moz-transition: opacity .3s linear;
		-webkit-transition: opacity .3s linear;
				
		.b2c-loader-label {
			position: relative;
			color: #555;
			letter-spacing: .35rem;
			padding-left: .4rem;
		}
  
		//Set content as the attribute which we have used in tag. This will cover the original text.
		//La carico a codice perchè il colore del testo è configurabile
		.b2c-loader-label::before { 
			
			letter-spacing: .35rem;
			padding-left: .4rem;
		
			// This will create the layer over original text
			content: attr(data-text); 
			position: absolute; 
			top: 0; 
			left: 0; 
		  
			// Setting different color than that of original text
			color: #fff; 
			overflow: hidden; 
			
			// Setting width to 0
			width: 0%; 
			transition: 2.5s; 
				
		}
			
		.b2c-loader-label-trs::before {
		
			width: 100%; 
	
		}
			
		.b2c-loader-bck-bar {
			
			position: relative;
			width: 10rem;
			height: 2px;
			background: #555;
			overflow: hidden;
				
			.b2c-loader-bar {
				position: absolute;
				width: 100%;
				height: 100%;
				transform: translate(-100%);
				transition: transform 2.5s cubic-bezier(.33,1,.68,1);
				-webkit-transition: transform 2.5s cubic-bezier(.33,1,.68,1);
				-moz-transition: transform 2.5s cubic-bezier(.33,1,.68,1);
			}
				
			.b2c-loader-bar-trs {
				transform: translate(100%, 0%) translate(-160px);
				-webkit-transition: translate(100%, 0%) translate(-160px);
				-moz-transition: translate(100%, 0%) translate(-160px);
			}
				
		}
			
		.b2c-percent-intro {
			height: 20px;
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
			color: rgba(255,255,255,0.6);
			margin: 0 auto;
			padding-top: 1.7rem;
		}
			
	}
		
	.b2c-overlay-video-hidden {
			opacity: 0;
	}
		
	/*
	.b2c-video-foreground,.b2c-video-background iframe {
	
		position: absolute;
		left: 0;
		width: 100%;
		pointer-events: none;
		overflow: hidden;
		
		.b2c-overlay-video {
			  
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			-webkit-transition: opacity .5s linear;
			transition: opacity .3s linear;
			-moz-transition: opacity .3s linear;
			-webkit-transition: opacity .3s linear;
				
			.b2c-loader-label {
				position: relative;
				color: #555;
				letter-spacing: .35rem;
				padding-left: .4rem;
			}
  
			//Set content as the attribute which we have used in tag. This will cover the original text.
			//La carico a codice perchè il colore del testo è configurabile
			.b2c-loader-label::before { 
			
				letter-spacing: .35rem;
				padding-left: .4rem;
			
				// This will create the layer over original text
				content: attr(data-text); 
				position: absolute; 
				top: 0; 
				left: 0; 
		  
				// Setting different color than that of original text
				color: #fff; 
				overflow: hidden; 
			
				// Setting width to 0
				width: 0%; 
				transition: 1s; 
				
			}
			
			.b2c-loader-label-trs::before {
			
				width: 100%; 
		
			}
			
			.b2c-loader-bck-bar {
			
				position: relative;
				width: 10rem;
				height: 2px;
				background: #555;
				overflow: hidden;
				
				.b2c-loader-bar {
					position: absolute;
					width: 100%;
					height: 100%;
					transform: translate(-100%);
					transition: transform 1s cubic-bezier(.33,1,.68,1);
					-webkit-transition: transform 1s cubic-bezier(.33,1,.68,1);
					-moz-transition: transform 1s cubic-bezier(.33,1,.68,1);
				}
				
				.b2c-loader-bar-trs {
					transform: translate(100%, 0%) translate(-160px);
					-webkit-transition: translate(100%, 0%) translate(-160px);
					-moz-transition: translate(100%, 0%) translate(-160px);
				}
				
			}
			
			.b2c-percent-intro {
				height: 20px;
				font-size: 14px;
				font-weight: 500;
				line-height: 20px;
				color: rgba(255,255,255,0.6);
				margin: 0 auto;
				padding-top: 1.7rem;
			}
			
		}
		
		.b2c-overlay-video-hidden {
			opacity: 0;
		}
  		
	}
	*/
	
	.b2c-video-foreground-mobile {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		cursor: pointer;
		background: #fff;
	}
	
	/*
	.b2c-video-foreground,.b2c-video-background iframe { 
			height: 300%; 
			top: -100%;
	}
	*/
	
	/*Dava problemi scalando definizione*/
	/*
	@media (min-aspect-ratio: 16/9) {
	
		.b2c-video-foreground,.b2c-video-background iframe { 
			height: 300%; 
			top: -100%;
		}

	}
		
	@media (max-aspect-ratio: 16/9) {
		
		.b2c-video-foreground,.b2c-video-background iframe { 
			width: 300%; 
			left: -100%;	
		}
		
	}
	*/
	
	.b2c-ero-scroll {	
		z-index: 100;
		position: absolute;
		left: 94%;
		bottom: 20px;
		transform: translate(-50%, -48%);	
	}
	
	.b2c-ero-scroll p {
		font-size: 1rem;
		font-weight: 400;
		margin-bottom: .6rem;
		color: #fff;
	}
	
	.b2c-ero-scroll-line {	
		height: 2.3rem;
		width: 2px;
		background-color: #fff;
		margin: auto;
	}
	
	.b2c-ero-audio {
		width: 34px;
		margin: 4rem 2rem;
		position: fixed;
		bottom: 20px;
		z-index: 100;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: flex-end;
		cursor: pointer;
	}

	.b2c-ero-audio-bar {	
		height: 10px;
		width: 4px;
		margin: 0 2px;
		border-radius: 5rem;
		transform-origin: bottom;
	}

}

@include media-breakpoint-down(xl) {

	.b2c-ero-audio {
		right: 0;
	}
	
}

@include media-breakpoint-up(xl) {

	.b2c-ero-audio {
		margin: 3rem 3rem !important;
		left: 0;
	}

	.b2c-video-spacer {
		background-color: #fff;
		bottom: 0;
		position: relative;
		height: 100%;
		padding-bottom: 52%;	
	}

}

.b2c-hero {

	min-height: 100vh;
	background: #000 center center no-repeat;
	background-size: cover;
	position: relative;
	
	.b2c-hero-content {
	
		position: absolute;
		color: #fff;
		
		.b2c-hero-title {
			font-family: Titillium Web; 
			font-size: 1.2rem; 
			font-weight: 400;
			letter-spacing: 1.35rem;
		}
		
		.b2c-hero-subtitle {
			font-family: Titillium Web; 
			font-size: 1.64rem; 
			font-weight: 400;
			padding-right: .19rem !important;
		}
		
		@include media-breakpoint-up(xl) {

			.b2c-hero-subtitle {
				font-size: 4rem !important;
			}

		}
	}
	
	.b2c-ero-scroll {	
		position: absolute;
		left: 94%;
		bottom: 0;
		transform: translate(-50%, -48%);	
	}
	
	.b2c-ero-scroll p {
		font-size: 1rem;
		font-weight: 400;
		margin-bottom: .6rem;
	}
	
	.b2c-ero-scroll-line {	
		height: 2.3rem;
		width: 2px;
		margin: auto;
	}
	
	.b2c-ero-audio {
		color: #fff;
		width: 34px;
		margin: 3rem;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 100;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: flex-end;
		cursor: pointer;
	}

	.b2c-ero-audio-bar {	
		height: 10px;
		width: 4px;
		margin: 0 2px;
		border-radius: 5rem;
		background-color: #fff;
		transform-origin: bottom;
	}

}

.b2c-hero:before {
	  content:"";
	  position: absolute;
	  top: 0;
	  right: 0;
	  bottom: 0;
	  left: 0;
	  background: linear-gradient(to top, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 100%);
}

@include media-breakpoint-up(xl) {

	.b2c-container-item {
		width: 92%;
		margin-bottom: 6rem !important;
		margin-top: -44% !important; 
	}

}

/*
.b2c-container-item-mt {
		
	//Da utilizzare solo se carico il video
	margin-top: -44% !important; 
	
}
*/

.b2c-product-bck {

	background-color: #fff;

	.row > * {
		padding-left: 3px !important;
		padding-right: 3px !important;
	}
	
}

.b2c-product-bck-scroll {
	padding-top: 23rem;
}

@include media-breakpoint-up(xl) {

	.b2c-product-bck-scroll {
		padding-top: 27rem !important;
	}

}

.b2c-item {

	.b2c-thumbnail-container, .b2c-product-container {

		position: relative;
		margin-bottom: 30px;
		background: #FFF;
		text-align: center;
		
		.b2c-product-image {
		
			position: relative;
			overflow: hidden;

			a {
				color: #222;
				text-decoration: none;
				background-color: transparent;
			}
			
			img {
				-webkit-transition: transform 1s;
				transition: transform 1s;
			}
			
			.b2c-product-onlyone {
				overflow: hidden;
				transition: all 0.6s ease-in-out;
			}
			
			.b2c-product-additional {
			
				position: absolute;
				top: 0;
				visibility: hidden;
				left: 0;
				right: 0;
				bottom: 0;
				opacity: 0;
				overflow: hidden;
				transition: all 0.6s ease-in-out;
				
			}
			
			.b2c-product-additional:hover, .b2c-product-onlyone:hover {
			
				img {
					transform: scale(1.05);
				}
				
			}
			
			.b2c-product-buttons {
			
				transform: translateY(20px);
				left: 0;
				right: 0;
				margin: auto;
				padding: 0;
				background: transparent;
				visibility: hidden;
				opacity: 0;
				transition: all 0.5s ease;
				position: absolute;
				z-index: 100;
				text-align: center;
				bottom: 15px;

				button {
					border-radius: 0;
					padding: .4rem 2.75rem;
				}
				
			}
			
			@include media-breakpoint-down(xl) {
			
				.b2c-product-buttons {
					bottom: 55px !important;
					visibility: visible !important;
					opacity: 1 !important;
				}
				
			}
			
		}

		.b2c-product-meta {

			padding: 15px;
			display: inline-block;
			background-color: rgba(0,0,0,.9);
			width: 100%;

			a {
				color: #fff;
				text-decoration: none;
			}
			
			.b2c-product-title {
				font-size: 1.6rem;
				font-weight: bolder;
				line-height: 100%;
				text-align: left;
				text-transform: none;
				margin-bottom: 10px;
			}
			
			.b2c-product-subtitle {
				opacity: .6;
				font-size: 1rem;
				color: #fff;
				line-height: 100%;
				text-align: left;
			}
		}

		.b2c-product-info {
		
			padding-left: 0;
			padding-right: 0;
			padding-top: .7rem;
			padding-bottom: 1.3rem;
			
			span {
				font-size: 1.05rem;
				letter-spacing: .1rem;
				text-transform: uppercase;
			}
			
		}
		
		@include media-breakpoint-up(xl) {
		
			.b2c-product-info, .b2c-product-info-sm {
				padding-top: 1.3rem !important;
				padding-bottom: 1.5rem !important;
			}
		
		}
		
		.b2c-product-info-sm {
		
			padding-left: 0;
			padding-right: 0;
			padding-top: .7rem;
			padding-bottom: 1.3rem;
			
			span {
				font-size: .75rem;
				letter-spacing: .075rem;
				text-transform: uppercase;
			}
			
		}
		
		@include media-breakpoint-up(xl) {
		
			.b2c-product-info-sm {
			
				span {
					font-size: .9rem !important;
				}
			
			}
				
		}
		
		.b2c-product-thumb {
		
			padding-bottom: 1.3rem;
			
			.b2c-cell-img {
				margin: 1rem .5rem .2rem 0;
				max-width: 1.5rem;
				min-width: 4.5rem;
				padding: .15rem;
				cursor: pointer;
				position: relative;
				border: 1px solid #e6e6e6;
			}
		
		}
		
	}
	
	@include media-breakpoint-up(xl) {
	
		.b2c-thumbnail-container:hover, .b2c-product-image:hover {
		
			.b2c-product-additional {
				
				visibility: visible;
				opacity: 1;
				
			}
			
			.b2c-product-buttons {
				visibility: visible;
				opacity: 1;
				transform: translateY(0);
			}
			
		}
		
	}
	
}

.b2c-footer {

	margin-bottom: 3rem;
	overflow: hidden;

	.b2c-icon-link {
		cursor: pointer;
	}
	
	.b2c-first-row {
	
		padding-top: 2.3rem;
		border-top: 2px solid rgba(0,0,0,0.1);
		
		.b2c-title {
			font-size: 1.4rem;
			text-align: center;
		}
		
		.b2c-subtitle {
			font-size: 0.9rem;
			text-align: center;
		}
	
	}
	
	.b2c-nl-row {
	
		padding-top: 2rem;
		  
		.b2c-input {
		
			width: 100%;
		
			label {
				font-size: 1.3rem;
				text-align: center;
			}
			
			input {
				border-radius: 0;
				border: 1px solid black;
				box-shadow: 0 0 !important;
				outline: none !important;
				border-right: 0 !important;
			}
				
			.b2c-input-group-text {
				background-color: #fff;
				border-top: 1px solid;
				border-radius: 0;
				border: 1px solid #000;
				border-left: 0 !important;
			}
				
			.b2c-form-floating {
				width: 80%;
			}
		}
		
		
		@include media-breakpoint-up(xl) {

			.b2c-input {
			
				width: 50% !important;
				
				.b2c-form-floating {
					width: 88% !important;
				}

			}

		}
		
		.b2c-agree {
			
			text-align: center;
			
			label {
				font-size: .9rem;
				text-align: center;
			}
			
			input[type="checkbox"] {
				height: 13px;
				width: 13px;
				margin-right: 10px;	
			}
			
		}
		
		.b2c-prefer {
			
			input[type="checkbox"] {
				height: 18px;
				width: 18px;
				margin-right: 10px;	
			}
			
		}
	
		.b2c-msg-icon svg {
		
			flex-shrink: 0;
			height: 1.2rem;
			margin-right: .5rem;
			width: 1.2rem;
			vertical-align: middle;
			
		}
		
		.b2c-msg-icon span {
		
			font-size: .9rem;
			
		}

	}
	
	.b2c-hist-row {
	
		.b2c-history {
			width: 90%;
			font-size: .8rem;
			margin-top: 2.5rem;
		}
	
		@include media-breakpoint-up(xl) {
		
			.b2c-history {
				width: 40%;
				font-size: .9rem;
			}
			
		}
		
		.b2c-link {
		
			font-size: .9rem;
			margin-top: 1rem;
			
			a:not(:last-child) {
				margin-right: 1rem;
				padding-right: 1rem;
				border-right: 1px solid rgba(0,0,0,0.5);
			}
			
			a:first-child {
				font-weight: 600;
			}
			
		}
		
		.b2c-menu {
			font-size: .9rem;
			margin-top: 1rem;
		}
		
		a {
			color: #000;
			text-decoration: none;
		}
		
		.b2c-a {
			font-weight: 100;
		}
		
		.b2c-a:hover {
			text-decoration: underline;
		}
	}
	
	.b2c-contact-row {
	
		width: 100%;
		padding-top: 2.3rem;
		margin-top: 2rem;
		border-top: 2px solid rgba(0,0,0,0.1);
	
		.b2c-powered {
			font-size: .9rem;
		}
		
		.b2c-contact {
			font-size: 0.6rem;
			padding-top: .25rem;
			text-align: center;
		}
		
		@include media-breakpoint-up(xl) {
		
			.b2c-contact {
				text-align: left !important;
			}
			
		}
		
	}
}

.b2c-page-width {
	margin: 0 auto;	
	padding: 0 1.5rem;	
}

@include media-breakpoint-up(xl) {
		
	.b2c-page-width {
		padding: 0 2rem;
	}
			
}
	
.b2c-src-res, .b2c-bar-title {

	padding-top: 87px;
	padding-bottom: 27px;
	background-color: #fff;

}

.b2c-src-res-nobanner, .b2c-bar-title-nobanner {

	padding-top: 127px;
	padding-bottom: 27px;
	background-color: #fff;

}

.b2c-src-res, .b2c-bar-title, .b2c-src-res-nobanner, .b2c-bar-title-nobanner {

	.b2c-src-res-title, .b2c-bar-res-title {	
	
		margin-bottom: 0;
		
		.b2c-src-res-input {
		
			font-size: .9rem;
			margin: 0 auto 3.5rem;
			max-width: 31rem;
		
			.b2c-search-field {
			
				background: transparent;
				padding-right: 0;
				box-shadow: 0 0 !important;
				border: 1px solid #000 !important;
				border-radius: 0;
				margin-top: .6rem;
				padding-left: 1.5rem;
				height: 2.9rem;
				border-right: 0 !important;
				border-left: 0 !important;
				
			}

			.b2c-search-field:focus {
				padding-bottom: 1rem;
				padding-top: 1rem;
				padding-left: 1.5rem;
				outline: 0 !important;
			}
					
			.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
				padding-left: 47px!important;
			}
			
			.form-floating > label {
				padding: 1.2rem .75rem !important;
			}
			
			.b2c-src-res-icon-s {
				cursor: pointer;
				border: 1px solid #000;
				border-left: 0;
				margin-top: .6rem;
				height: 2.9rem;
			}
			
			.b2c-src-res-icon-c {
				cursor: pointer;
				border: 1px solid #000;
				border-right: 0;
				margin-top: .6rem;
				height: 2.9rem;
			}
			
		}
		
		.b2c-src-res-txt {
			margin-top: 0;
			margin-bottom: 1.5rem;
			font-weight: 500;
			letter-spacing: 1.6px;
		}
			
		.b2c-bar-res-txt {
			text-transform: uppercase;
			margin-top: 0;
			margin-bottom: 0;
			font-weight: 500;
			letter-spacing: 1.6px;
		}
	}
	
	@include media-breakpoint-up(xl) {
		
		.b2c-src-res-title {	
			margin-bottom: 3rem;
		}
		
		.b2c-bar-res-title {	
			margin-bottom: 0;
		}
		
		.b2c-bar-res-txt {
			margin-bottom: 1.5rem !important;
		}
		
	}

}

@include media-breakpoint-up(xl) {
		
	.b2c-src-res, .b2c-bar-title {	
		padding-top: 136px;
		padding-bottom: 36px;
	}
	
	.b2c-src-res-nobanner, .b2c-bar-title-nobanner {	
		padding-top: 96px;
		padding-bottom: 36px;
	}
			
}

.b2c-src-badges {

	padding: 0 1.5rem 2rem 1.5rem;
	letter-spacing: .04rem;
		
	.b2c-badge {
		cursor:pointer;
		text-align: center;
		text-transform: uppercase;
		font-size: .9rem;
		white-space: nowrap;
		display: inline-block;
		padding: .45em .75em;
		line-height: 1;
		border-radius: 50rem !important;
		border: 1px solid #316F2E;
		box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 0px 1px
	}
	
	.b2c-badge:hover {
		background: #316F2E;
		color: #fff;
		borderr: 1px solid rgba(0, 0, 0, 0.2);
	}

	.b2c-badge-link {
		
		font-size: .9rem;
			
		a {
			color:#000 !important;
		}
			
		a:hover {
			text-decoration-thickness: 2px;
		}
			
	}
		
}

@include media-breakpoint-up(xl) {
		
	.b2c-src-badges {	
		
		padding: 0 2rem 2rem 2rem;
		
	}
			
}

.b2c-src-filter {
	
	padding: 0 13px 0 13px;
	margin: 0 13px;
	border-top: 1px solid rgba(0,0,0,.1);
	
	.b2c-input {

		border-radius: 0;
		border: 1px solid black;
		box-shadow: 0 0 !important;
		outline: none !important;

	}

	.b2c-reset-link {
		
		font-size: .85rem;
			
		a {
			color:#000;
		}
			
		a:hover {
			text-decoration-thickness: 2px;
		}
			
	}
		
	.dropdown-toggle::after {
		  border-top: .45em solid;
		  border-right: .6em solid transparent;
		  border-left: .6em solid transparent;
		  margin-top: 3px;
	}
	
	.b2C-dropdown-btn, .b2C-dropdown-lbl, .b2C-dropdown-sort-btn {
	
		font-size: .9rem;
		text-transform: uppercase;
		letter-spacing: .04rem;
		
		svg {
			vertical-align: baseline;
		}
	}
	
	.b2C-dropdown-btn, .b2C-dropdown-sort-btn {
		font-weight: 100;
		padding-top: .36rem;
	}
	
	.b2C-dropdown-lbl {
		font-weight: 600;
	}
	
	.b2C-dropdown-btn:focus, .b2C-dropdown-sort-btn:focus {
		box-shadow: 0 0 0 .25rem rgba(255,255,255,1) !important;
	}
	
	.b2C-dropdown-btn:hover, .b2C-dropdown-sort-btn:hover {
		text-decoration: underline;
		text-decoration-thickness: 1px;
	}

	.b2C-dropdown-sort {
	
		margin-top: -2px;
		
		.b2C-dropdown-sort-btn {
			padding-top: 0;
			font-weight: 100;
		}
		
		.b2C-dropdown-ul {
		
			background-color: #fff;
			border: rgba(0,0,0,0.2) solid 1px;
			border-radius: .25rem;
			font-size: 1.1rem;
			float: right !important;
			right: 0;
			left: auto;
			
			li {
				font-size: .9rem;
			}
			
		}
		
		.b2C-dropdown-li-active {
			
			background-color: #000;
			
			a {
				color:#fff !important;
			}
			
		}
	
		.dropdown-item {
			text-transform: uppercase;
		}
		
		.dropdown-item:hover, .dropdown-item:focus {
			
			background-color: #282828cf!important;
			color:#fff !important;
			
		}
		
	}
		
	.b2C-dropdown-ul {
	
		background-color: #fff;
		border-bottom: rgba(0,0,0,0.2) solid 1px;
		border-left: 0;
		border-right: 0;
		border-top: 0;
		border-radius: 0;
		font-size: 1.1rem;
		min-width: 24rem;
		
		.b2C-dropdown-firstrow {
		
			border-bottom: 1px solid rgba(0,0,0,0.2);
			
			.b2c-dropdown-iconsrc {			
			
				cursor: pointer;
				
			}
			
		}
		
		.b2C-dropdown-li {
			padding: 13px;
		}
		
		.b2c-dropdown-txt {
			font-size: .85rem;
		}
		
		.b2c-dropdown-link {
		
			font-size: .85rem;
			
			a {
				color:#000;
			}
			
			a:hover {
				text-decoration-thickness: 2px;
			}
			
		}
		
		.b2c-dropdown-check {
		
			text-transform: uppercase;
			font-size: .9rem;
			
			input {
				margin-right: 10px !important;
			}
			
		}
		
	}
	
}

.b2c-src-filter-mob {

	font-size: .9rem;
	
	.b2c-icon-filter {
		cursor: pointer;
	}
	
	.b2c-text-filter {
	
		cursor: pointer;
		
		span:hover {
			text-decoration-thickness: 1px;
			text-decoration-line: underline;
			text-underline-offset: 3px;
		}
		
	}
	
}

.b2c-src-filter-menu-mob {

	.b2C-dropdown-firstrow {
			
		.b2c-dropdown-iconsrc {			
			
			cursor: pointer;
				
		}
			
	}
	
	.b2C-dropdown-li {
		padding: 13px;
	}
		
	.b2c-dropdown-txt {
		font-size: .85rem;
	}
		
	.b2c-dropdown-link {
		
		font-size: .85rem;
		
		a {
			color:#000;
			text-decoration: underline;
		}
			
		a:hover {
			text-decoration-thickness: 2px;
		}
			
	}
		
	.b2c-dropdown-check {
		
		text-transform: uppercase;
		font-size: .9rem;
			
		input {
			margin-right: 10px !important;
		}
		
	}			

}

.b2c-offcanvas-flt {

	width: 340px !important;
	border-left: 0 !important;

	.b2c-offcanvas-header {
	
		font-size: .9rem;
		border-bottom: 1px solid rgba(0,0,0,0.2);
		
		.b2c-flt-title {
			font-weight: 600;
		}
		
	}
				
	a:hover {
		color:#000 !important;
		text-decoration: none !important;
	}
	
	.b2c-btn-close {
		width: .25rem;
		height: .25rem;
		margin-top: 0;
		margin-right: 0;
		opacity: .95;
		margin-bottom: .7rem;
		background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat !important;
	}
		
	.b2c-accordion-header {
		cursor: pointer;
	}
	
	.b2c-accordion-collapse {
		border-bottom: 0;
	}
	
	.b2c-accordion-button {
		padding: .65rem .1rem;
		font-weight: 600;
		font-size: 1.15rem;
	}
	
	.b2c-accordion-button.collapsed {
		border-bottom: 0;
	}
	
	.b2c-accordion-button:after {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' fill='none'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' fill='currentColor' d='M1 4.51a.5.5 0 000 1h3.5l.01 3.5a.5.5 0 001-.01V5.5l3.5-.01a.5.5 0 00-.01-1H5.5L5.49.99a.5.5 0 00-1 .01v3.5l-3.5.01H1z'/%3e%3c/svg%3e") !important;
	}
	
	.accordion-button:not(.collapsed)::after {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 2' fill='none'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' fill='currentColor' d='M.5 1C.5.7.7.5 1 .5h8a.5.5 0 110 1H1A.5.5 0 01.5 1z'/%3e%3c/svg%3e") !important;
		background-position-y: 8px;
	}

	.b2c-accordion-button:not(.collapsed) {
		color: #000;
		background-color: #fff;
		box-shadow: none;
	}

	.b2c-accordion-list {
	
		padding: .75rem 0 .75rem 1rem !important;
		font-weight: 600;
		font-size: 1.15rem;
		
	}
	
	.b2c-accordion-list-two {
		
		padding: .75rem 0 .75rem .75rem !important;
		font-size: 1.1rem;
						
	}

	.b2c-dropdown-check {
		
			text-transform: uppercase;
			font-size: .9rem;
			
			input {
				margin-right: 10px !important;
			}
			
	}
	
	.b2c-offcanvas-body {
	
		font-size: 1.15rem;
		
		.b2c-canvas-menu-row {
			cursor: pointer;
		}
		
		.b2c-menu-txt {
			font-weight: 600;
		}
		
	}
	
	.b2c-offcanvas-footer {
	
		font-size: .9rem;
		border-top: 1px solid rgba(0,0,0,0.2);
		
	}
	
}

.b2c-mainprod-det {

	margin: 7.75rem 0 0 0;
  
	.b2c-mainprod-item {
	
		overflow: hidden !important;
		margin-bottom: 1rem;
		padding: 0 1.5rem;
  
		.b2c-mainprod-icon {
		
			background-color: #fff;
			border-radius: 50%;
			border: .1rem solid #fff;
			color: #000;
			height: 2rem;
			width: 2rem;
			position: relative;
			padding: .3rem .3rem;
			left: .4rem;
			top: 2.5rem;
			z-index: 1;
			cursor: pointer;
			opacity: 0;
			transition: opacity .9s ease;
		
		}
			
	}
	
	.b2c-mainprod-item:hover {
		
		.b2c-mainprod-icon {
			opacity: 1 !important;
		}
		
	}

	.b2c-mainprod-info {
	
		padding: .25rem 1.7rem;
		
		.b2c-breadcrumb {
		
			font-size: 1.08rem;
			padding-bottom: 1rem;
			border-bottom: 1px solid rgba(112,122,122,.2);
  
			a {
				color: #000;
				text-decoration: none;
				font-weight: 600;
				
				.icon-back {
					height: 6px;
					margin-bottom: 4px;
				}
				
			}
			
		}
		
		.b2c-prod-header {
			font-size: 1.45rem;
			line-height: 2.4rem;
			padding: 1rem 0;
			font-weight: 400;
		}
	
		.b2c-prod-price {
			font-size: 1.2rem;
			line-height: 2.1rem;
			letter-spacing: .13rem;
		} 
		
		.b2c-prod-code {
			font-size: .75rem;
			font-weight: 400;
			line-height: 2.1rem;
		}
		
		.b2c-product-menu {
		
			padding-top: 1.25rem;
			
			.b2c-accordion-button {
				font-size: 1rem;
				font-weight: 200;
			}
			
			.b2c-accordion-list-two {
				padding: .1rem 0 .1rem .175rem !important;
				font-size: .85rem;
			}
		
			.b2c-accordion-collapse > :last-child {
					padding-bottom: 1rem !important; 
			}
		
		}
		
		.b2c-prod-size, .b2c-prod-color {
		
			padding-top: 2.5rem;
					
			.b2c-title {
				font-size: 1rem;
				font-weight: 200;
			}
			
			.b2c-cell {
			
				margin: 1rem .5rem .2rem 0;
				padding: 1.2rem 2rem;
				font-size: .8rem;
				letter-spacing: .1rem;
				line-height: .8;
				text-align: center;
				transition: border .1s ease;
				cursor: pointer;
				position: relative;
				min-width: 4.5rem;
				border: 1px solid #e6e6e6;
  
				.b2c-cell-span {
				
					border-radius: 50%;
					display: block;
					height: 35px;
					width: 35px;
					
				}
  
			}
			
			.b2c-cell-img {
				margin: 1rem .5rem .2rem 0;
				max-width: 1.5rem;
				min-width: 4.5rem;
				padding: .15rem;
				cursor: pointer;
				position: relative;
				border: 1px solid #e6e6e6;
			}
			
			.b2c-cell-color {
				padding: .55rem 1.48rem !important;
			}
			
			.b2c-cell:hover, .b2c-cell-img:hover { 
				background-color: #e6e6e67d;
			}
			
			.b2c-cell-active {
				border-bottom: 2px solid;
			}
			
		}
		
		.b2c-prod-addcart {
		
			padding-top: 3.5rem;
			
			button {
				width: 100%;
				border-radius: 0;
			}
			
		}
		
	}

	.b2c-mainprod-info > :first-child {
		margin-top: 0;
	}
	 
	.b2c-mainprod-info > * + * {
		margin: 1rem;
	}

	@include media-breakpoint-up(xl) {
			
		.b2c-mainprod-info {
			padding: 1.7rem 5rem 0 5rem !important;
		}
				
	}

	.b2c-mainprod-divider {
		border-bottom: 2px solid rgba(112,122,122,0.2);
		margin: 3rem .7rem;
	}

	.b2c-mainprod-descr {
	
		padding: 0 2.5rem;
		margin-bottom: 3.5rem;
		
		.b2c-d-title {
			font-size: 1.35rem;
		}
	
		.b2c-d-descr {
			font-size: 1rem;
			line-height: 1.5;
		}
		
		@include media-breakpoint-up(xl) {
			
			.b2c-d-title {
				font-size: 2rem;
			}
				
		}
		
	}

	.b2c-mainprod-gall  {
	
		padding: 0 1.5rem;
		margin-bottom: 3rem;
		overflow: hidden;
	
		.row .col:first-child {
			padding: 0 !important;
		}

		.row .col {
			padding: 0 0 0 5px !important;
		}

	}
	
	.b2c-main-swiper {
	
		padding: 0;
		margin: 0;
  
		.b2c-swiper-title {
		
			margin-bottom: 1.5rem;
			font-weight: 400;
			font-size: 1rem;
  
		}
  
		.b2c-swiper {
			max-width: 100%;
			height: 100%;
		}
	
		.b2c-swiper-wrapper {
			padding-left: 1rem;
		}
			
		.b2c-swiper-slide {
			
		}
		
		@include media-breakpoint-up(xl) {
			
			.b2c-swiper-wrapper {
				padding-left: 4rem;
			}
				
			.b2c-swiper-title {
				margin-bottom: 2.5rem;
				font-size: 1.7rem;
			}
		
		}
	}

}

.b2c-full-photo {

	.b2c-btn-close {
	
		border: .1rem solid #e6e6e6;
		border-radius: 50%;
		background-color: #fff;
		color: #e6e6e6;
		display: flex;
		cursor: pointer;
		right: 4rem;
		padding: .9rem;
		position: fixed;
		z-index: 2;
		top: 2rem;
		width: 3rem;
	
	}

}

.b2c-unsubscribe {

	font-size: 1.35rem;
	
	.b2c-padding-x {
		padding-left: .25rem;
		padding-right: .25rem;
	}
	
	@include media-breakpoint-up(xl) {
		
		.b2c-padding-x {	
			padding-left: 25rem;
			padding-right: 25rem;
		}
			
	}
	
}

.b2c-assistance {

	font-size: 1.2rem;
	font-weight: 400;
	letter-spacing: 0.06rem;
	line-height: 1.38;
		
	a {
		color: #000;
	}
	
	a:hover {
		color: #000;
		text-decoration-thickness: 2px !important;
	}
}

.b2c-recapiti {

	font-size: 1.1rem;
	font-weight: 400;
	letter-spacing: 0.06rem;
	line-height: 1.38;
		
	a {
		color: #000;
	}
	
	a:hover {
		color: #000;
		text-decoration-thickness: 2px !important;
	}
	
	.b2c-recapiti-header {
		font-weight: 700 !important;
		font-size: 1.5rem;
		border-bottom: 0 !important;
		padding-bottom: 0 !important;
	}
}

.b2c-cart {

	.b2c-cart-offcanvas {
		width:500px!important;
	}

	.b2c-cart-header {
		font-size: 14px !important;
		line-height: 30px !important;
		font-weight: 600 !important;
		text-align: center !important;
		color: #000000 !important;
		border-style: solid;
		border-width: 0 0 1px 0;
		border-color: #e5e5e5;
    }
  
	.b2c-cart-body {
	
		border-top: 1px solid #e5e5e5;
		padding: 15px 10px 15px 20px;
		line-height: 1.6;
		margin: 0;
		
		.b2c-cart-body-col {
			padding-left: 0;
		}
  
		.b2c-cart-body-col:last-child {
			padding-right: 0;
		}
		
		.b2c-cart-body-name {
			font-weight: 600;
			font-size: 14px;
			line-height: 16px;
		}
		
		.b2c-cart-body-attr {
			font-weight: normal;
			font-size: 14px;
		}
		
		.b2c-cart-body-price {
			font-weight: normal;
			font-size: 14px;
		}
		
		.b2c-cart-body-qty {
		
			font-size: 14px;
			border-radius: 2px;
			align-items: center;
			
			.b2c-cart-qtybtn-desc, .b2c-cart-qtybtn-asc {
			
				border: 1px solid #ccc !important;
				cursor: pointer;
				align-items: center;
				-webkit-box-pack: center;
				width: 30px;
				height: 30px;
				font-weight: 700;
				text-align: center;
				-webkit-box-align: center;
				
			}
			
			.b2c-cart-qtybtn-desc {
				border-top-left-radius: 2px;
				border-bottom-left-radius: 2px;
			}
			
			.b2c-cart-qtybtn-val{
				border-top: 1px solid #ccc !important;
				border-bottom: 1px solid #ccc !important;
				border-left: 0px !important;
				border-right: 0px !important;
				width: 45px !important;
				height: 30px;
				text-align: center;
				line-height: 30px;
			}
			
			.b2c-cart-qtybtn-asc {
				border-top-right-radius: 2px;
				border-bottom-right-radius: 2px;				
			}
			
		}
		
		.spinner-border {
			position: absolute;
			top:0;
			left: .25rem;
			width: 1.75rem;
			height: 1.75rem;
			border: .2em solid #d9d7d7;
			border-right-color: transparent;
			border-radius: 50%;
		}
		
		@include media-breakpoint-up(xl) {
	
			.b2c-cart-body-name {	
				font-size: 16px;
			}
			
			.b2c-cart-body-attr {
				font-size: 16px;
			}
			
			.b2c-cart-body-qty {		
				font-size: 16px;
			}
		
			.b2c-cart-body-price {
				font-size: 16px;
			}
		
		}
		
	}
	
	.b2c-cart-body:first-child {
	
		border-top: 0px;
		padding-top: 0;
		
	}
	
	.b2c-cart-empty {
	
		padding-top: 20px;
		line-height: 1.6;
		
		.b2c-cart-empty-title {
			font-weight: 700 !important;
			font-size: 18px !important;
		}
		
		.b2c-cart-empty-msg {
			font-weight: normal;
			font-size: 13px;
		}
	
		@include media-breakpoint-up(xl) {
	
			.b2c-cart-empty-msg {	
				font-size: 16px;
			}
			
		}
	
		.b2c-cart-empty-btn {
			color: #ffffff !important;
			background: #316F2E !important;
			font-size: 16px !important;
			border-radius: 0px !important;
			margin: 0;
			padding: 10px 15px;
			width: 100%;
		}
		
	}
	
    .b2c-cart-footer {
  
	   font-size: 16px !important;
	   font-weight: 700;
	   background: #ffffff !important;
	   border-style: solid;
	   border-width: 1px 0 0 0;
	   border-color: #e5e5e5 !important;
	   flex: 0 0 auto;
	   margin: 0;
	   padding: 15px 20px;
	   
	   .b2c-cart-price {
			font-weight: 500;
	   }
	   
	   .b2c-cart-continue {
			font-size: 11px !important;
			font-weight: 500 !important;
			line-height: 11px !important;
			text-decoration: underline !important;
			text-underline-offset: 0.3rem !important;
			color: #5b5858 !important;
	   }
  
	   .b2c-cart-continue:hover {
			color: #000000 !important;
	   }
	   
	   svg {
			height: 16px !important;
			width: 16px !important;
	   }
		
	}
  
}

.b2c-checkout, .b2c-account {
		
	.b2c-checkout-col1 {
		overflow: scroll;
		height: 100vh;
		scrollbar-width: none;
	}
	
	.b2c-checkout-col1, .b2c-account-col1, .b2c-account-col2 {
	
		.b2c-account-col1-content {
			padding-top: 7.5rem !important;
		}

		.b2c-checkout-col1-content, .b2c-account-col1-content {
		
			padding-top: 1rem;
			width: 100%;
			
			.b2c-account-col1-fs {
				font-size: 1rem;
				letter-spacing: 0.06rem;
				line-height: 1.38;
			}
			
		}
		
		.b2c-checkout-col1-header {
		
			img {
				width: 75%;
			}
			
		}
		
		.b2c-account-col1-header {
		
			font-size: 1.5rem;
			letter-spacing: 0.06rem;
			line-height: 1.38;
	
		}
		
		.b2c-account-noorder {
			font-size: 1rem;
			letter-spacing: 0.06rem;
			line-height: 1.38;
		}
		
		.b2c-div-input {
			width: 300px;
		}
		
		.b2c-input {
			
			width: 100%;
				
			label {
				font-size: 1rem;
				text-align: center;
				padding: .6rem .75rem !important;
			}
					
			input {
				border-radius: 0;
				border: 2px solid black;
				box-shadow: 0 0 !important;
				outline: none !important;
				border-right: 0 !important;
				height: 40px;
			}
						
			.b2c-input-group-text {
				background-color: #fff;
				border-top: 1px solid;
				border-radius: 0;
				border: 2px solid #000;
				border-left: 0 !important;
			}
						
			.b2c-form-floating {
				width: 80%;
			}
			
		}
	
		.b2c-checkout-col1-bdcrumb {
		
			font-size: .75rem;
			border-bottom-width: 0.8px;
			border-bottom-style: solid;
			border-bottom-color: rgba(0, 0, 0, 0.15);
			
			a {
				color: rgb(5, 41, 51);
				text-decoration: none;
			}
			
			a:hover {
				text-decoration: underline;
				text-underline-offset: .2rem;
			}
			
			.not-active {
				color: rgba(0, 0, 0, 0.56);
				pointer-events: none;
			}
				
			.active {
				text-decoration: underline;
				text-underline-offset: .2rem;
				pointer-events: none;
			}
			
		}
		
		@include media-breakpoint-up(xl) {
	
			.b2c-account-col1-content {
			
				padding-top: 8rem !important;
				
				.b2c-account-col1-fs {
					font-size: 1.1rem;
					letter-spacing: 0.06rem;
					line-height: 1.38;
				}
			
			}
			
			.b2c-checkout-col1-content {
				padding-top: 4rem;
				padding-right: 30px;
				padding-left: 30px;
				width: 90% !important;
			}
			
			.b2c-account-col1-content {
				padding-top: 4rem;
				padding-right: 30px;
				padding-left: 20px;
				width: 96% !important;
			}
			
			.b2c-checkout-col1-header {
			
				img {
					width: 50%;
				}
				
			}
		
			.b2c-account-col1-header {
		
				font-size: 1.8rem;
				letter-spacing: 0.06rem;
				line-height: 1.38;
				
			}
		
			.b2c-account-noorder {
				font-size: 1.1rem;
				letter-spacing: 0.06rem;
				line-height: 1.38;
			}
		
			.b2c-checkout-col1-bdcrumb {
				font-size: .9rem;
			}
			
		}
		
		.b2c-checkout-col1-body, .b2c-checkout-col2-body {
		
			font-size: .9rem;
			
			.b2c-title {
				font-size: 1.1rem;
				font-weight: 600;
			}
			
			.b2c-sub-title {
				font-size: .9rem;
				color:rgba(0,0,0,0.6)
			}
			
			a {
				text-decoration: underline;
				color: #000;
			}
			
			.form-floating > label {
				color: rgba(0, 0, 0, 0.46);
			}

			.b2c-sendemail,.b2c-remember {
			
				input[type="checkbox"] {
					height: 18px;
					width: 18px;
					margin-right: 10px;	
				}
			
			}
			
			.b2c-remember {
			
				font-size: .8rem;
				border: 1px solid rgba(0,0,0,0.15);
				border-radius: 5px;
				height: 70px;
  
			}
			
			@include media-breakpoint-up(xl) {
				.b2c-remember {
					font-size: 1rem;
				}
			}
			
			.b2c-checkout-riepilogo {
			
				border: 1px rgba(0,0,0,0.15) solid;
				border-radius: 5px;
				font-size: .9rem;
				
				.b2c-checkout-contatto {
					border-bottom: 1px rgba(0,0,0,0.15) solid;
				}
			
			}
					
			.b2c-accordion, .b2c-shipping-select {
			
				input[type="radio"] {
					height: 18px;
					width: 18px;
					margin-right: 10px;	
				}
				
				input[type="checkbox"] {
					height: 18px;
					width: 18px;
					margin-right: 10px;	
				}
				
				.b2c-accordion-pay-button, .b2c-accordion-shp-button, .b2c-accordion-reg-button {
					background-color: #fff;
					border: 1px rgba(0,0,0,0.15) solid;
					padding: 1.1rem .75rem;
					font-size: 1.1rem;
				}
						
				.b2c-accordion-pay-button-first-child, .b2c-accordion-shp-button-first-child, .b2c-accordion-reg-button-first-child {
					border-top-right-radius: 5px;
					border-top-left-radius: 5px;
				}
				
				.b2c-accordion-pay-button-last-child, .b2c-accordion-shp-button-last-child, .b2c-accordion-reg-button-last-child {
					border-bottom-right-radius: 5px;
					border-bottom-left-radius: 5px;
				}
				
				.b2c-accordion-pay-button-active, .b2c-accordion-shp-button-active {
					background-color: rgb(236, 248, 254) !important;
					border: 1px #000 solid !important;
				}
				
				.b2c-accordion-pay-button-active {
					pointer-events: none;
				}
				
				.b2c-accordion-reg-button-active {
					background-color: rgb(236, 248, 254) !important;
					border: 1px #000 solid !important;
				}
				
				.b2c-accordion-collapse {
					border-left: 1px rgba(0,0,0,0.15) solid;
					border-right: 1px rgba(0,0,0,0.15) solid;
					background-color: rgba(0, 0, 0, 0.045);
				}
			
			}
			
		}
		
		@include media-breakpoint-up(xl) {
		
			.b2c-checkout-col1-body, .b2c-checkout-col2-body {
		
				font-size: 1.1rem;
			
				.b2c-title {
					font-size: 1.3rem;
				}
			
				.b2c-sub-title {
					font-size: 1rem !important;
				}
			
			}
		
			.b2c-checkout-riepilogo {
				font-size: 1rem !important;
			}
			
			.b2c-accordion-pay-button {
				font-size: 1.3rem;
			}
			
		}
		
		.b2c-checkout-col1-footer {
		
			font-size: .95rem;
			border-bottom-width: 0.8px;
			border-bottom-style: solid;
			border-bottom-color: rgba(0, 0, 0, 0.15);
			
			a {
				text-decoration: none;
				color: #000;
			}
			
			a:hover {
				text-decoration: underline;
				text-underline-offset: .2rem;
			}
			
			button {
				background-color: #316F2E;
				border-color: #316F2E;
				border-radius: 4px;
				height: 3.5rem;
			}
			
			.b2c-btn-paypal {
				background-color: #009bdb; 
				border-radius: 4px;
				height: 3.5rem;
				width: 13rem;
				color: #fff;
			}
			
		}
		
		@include media-breakpoint-up(xl) {
		
			.b2c-checkout-col1-footer {
			
				font-size: 1.1rem;
				
				button {
					height: 3.7rem;
				}
				
			}
			
		}
		
		.b2c-checkout-col1-info {
		
			font-size: .75rem;
			line-height: 1.5rem;
			
			a {
				text-decoration: underline;
				color: #000;
			}
			
		}
		
		@include media-breakpoint-up(xl) {
		
			.b2c-checkout-col1-info {
		
				font-size: .9rem;
			
			}
		
		}
		
		.b2c-accordion-cart, .b2c-accordion-account {
		
			.accordion-button::after {
				margin-left: 0 !important;
			}
		
			.b2c-accordion-button {
				font-size: .85rem;
				padding: 1rem .5rem;
				background-color: rgb(242, 242, 242);
				border-bottom-width: 0.8px;
				border-bottom-style: solid;
				border-bottom-color: rgba(0,0,0,0.15);
				color: #000;
			}
			
			.b2c-accordion-header {
				background-color: #fafafa;
			}
			
			.b2c-accordion-body {
				border-bottom-width: 0.8px;
				border-bottom-style: solid;
				border-bottom-color: rgba(0,0,0,0.15);
				padding-top: .25rem;
			}
			
		}
		
		.b2c-account-select {
		
			select {
		      border-color: #D5D9D9;
			  border-radius: 8px;
			  color: #0F1111;
			  background: #F0F2F2;
			  box-shadow: 0 2px 5px 0 rgba(213,217,217,.5);
			  border-style: solid;
			  border-width: 1px;
			  text-align: center;
			  font-size: 0.975rem;
			}
			
		}
		
		.b2c-account-card {
		
			a {
				text-decoration: underline;
				text-underline-offset: .2rem;
				color: #000;
			}
		
			.b2c-account-card-header {
			
				background-color: #F0F2F2 !important;
				padding: .75rem 1rem !important;
				
				span {
					color: #565959 !important;
					letter-spacing: 0.06rem;
				}
				
			}
			
			.span-size-min {
				font-size: .825rem;
				line-height: 1.18;
			}
				
			.span-size-base {
				font-size: .925rem;
				line-height: 1.38;
			}
				
			.b2c-account-card-body {
			
				.b2c-account-card-row {
			
					padding: 11px;
					
					.b2c-account-card-btn {
						line-height: 1;
					}
			
					@include media-breakpoint-up(xl) {
						.b2c-account-card-btn {
							line-height: 1.25;
						}
					}
			
				}
				
			}
		
		}
		
	}
	
	.b2c-account-col2-dtls-content {
		
		.b2c-account-col2-header {
		
			font-size: 1.5rem;
			letter-spacing: 0.06rem;
			line-height: 1.38;;
	
		}
		
		.b2c-account-col2-fs {
			font-size: 1rem;
			letter-spacing: 0.06rem;
			line-height: 1.38;
		}
		
		a {
			text-decoration: underline;
			text-underline-offset: .2rem;
			color: #000;
		}
		
		.b2c-account-first-adr {

			border-style: dashed;
			height: 240px;
			width: 320px;
			border-width: 2px;
			box-sizing: border-box;
			border-color: #C7C7C7;
			text-align: center;
			display: table-cell;
			border-radius: 8px;
			background-color: #fff;

		}
		
		.b2c-account-other-adr {
		
			height: 270px;
			border-width: 1px;
			box-sizing: border-box;
			border-color: #C7C7C7;
			box-shadow: 0 2px 1px 0 rgba(0,0,0,.16);
			border-style: solid;
			border-radius: 8px;
			background-color: #fff;
			
			.b2c-default-section {
				height: 40px;
				border-bottom: 1px solid #C7C7C7;
			}

			a {
				font-size: .725rem;
			}
			
		}
		
	}
		
	@include media-breakpoint-up(xl) {
		
		.b2c-account-col2-dtls-content {
				
			padding-right: 10px;
			padding-left: 10px;
			
			.b2c-account-col2-header {
		
				font-size: 1.8rem;
				letter-spacing: 0.06rem;
				line-height: 1.38;;
		
			}
		
			.b2c-account-col2-fs {
				font-size: 1.1rem;
				letter-spacing: 0.06rem;
				line-height: 1.38;
			}
			
			.b2c-account-first-adr, .b2c-account-other-adr {
				width: 400px;
			}
			
			.b2c-account-other-adr {
				a {
					font-size: .9rem;
				}
			}
		}
			
	}
		
	.b2c-checkout-col2, .b2c-account-col2 {
			
		border-left-width: 1px;
		border-left-style: solid;
		border-left-color: rgb(218,218,218);
		background-color: rgb(250, 250, 250);
		
		.b2c-checkout-col2-content {
			padding-top: 1rem;
			padding-right: 30px;
			padding-left: 20px;
			padding-bottom: 2rem;
		}
		
		.b2c-account-col2-content {
			padding-top: 8rem;
			padding-right: 10px;
			padding-left: 10px;
			padding-bottom: 1rem;
		}
		
		.b2c-checkout-col2-bdcrumb {
		
			font-size: .75rem;
			border-bottom-width: 0.8px;
			border-bottom-style: solid;
			border-bottom-color: rgba(0, 0, 0, 0.15);
			
			a {
				color: rgb(5, 41, 51);
				text-decoration: none;
			}
			
			a:hover {
				text-decoration: underline;
				text-underline-offset: .2rem;
			}
			
			.not-active {
				color: rgba(0, 0, 0, 0.56);
				pointer-events: none;
			}
				
			.active {
				text-decoration: underline;
				text-underline-offset: .2rem;
				pointer-events: none;
			}
			
		}
		
		@include media-breakpoint-up(xl) {
		
			.b2c-checkout-col2-bdcrumb {
				font-size: .9rem;
			}
			
		}
	}
	
	.b2c-cart-body-ck {
	
		padding: 5px 10px 5px 20px;
		line-height: 1.6;
		margin: 0;
			
		.b2c-cart-body-col {
			padding-left: 0;
		}
		  
		.b2c-cart-body-col:last-child {
			padding-right: 0;
		}
		
		.b2c-cart-body-name {
			font-weight: 600;
			font-size: 14px;
			line-height: 16px;
		}
				
		.b2c-cart-body-attr {
			font-weight: normal;
			font-size: 14px;
		}
				
		.b2c-cart-body-price {
			font-weight: normal;
			font-size: 14px;
		}
		
	}
		
	@include media-breakpoint-up(xl) {

		padding: 0 5px 5px 5px;
				
		.b2c-cart-body-name {
			font-size: 16px;
		}
				
		.b2c-cart-body-attr {
			font-size: 16px;
		}
				
		.b2c-cart-body-price {
			font-size: 16px;
		}
		
	}
		
	.b2c-cart-count-ck {
		position: relative;
		background-color: rgb(0, 0, 0);
		color: rgb(255, 255, 255);
		height: 1.5rem;
		width: 1.5rem;
		border-radius: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: .8rem;
		top: .5rem;
		right: .5rem;
		line-height: 1;
	}
	
	.b2c-checkout-col2-riep-ck {
		
		font-size: 1.1rem;
			
		.b2c-totale {
			font-size: 1.3rem;
			font-weight: 600;
		}
			
	}

	@include media-breakpoint-up(xl) {

		.b2c-checkout-col2-riep-ck {
		
			font-size: 1rem;
				
			.b2c-totale {
				font-size: 1.2rem;
				font-weight: 600;
			}

		}
		
	}
	
	.b2c-checkout-prfx { 
	
		ul { 
			
			max-height: 300px;
			overflow-y: scroll;
			
			li { 
				padding: .25rem .75rem;
			}
			
			li:hover { 
				background-color: rgba(0, 0, 0, 0.1);
			}
		}
		
	}
	
}

.b2c-login, .b2c-register {

	.b2c-login-content, .b2c-register-content {
	
		width: 100%;	
		margin: 9rem auto 9rem;
		max-width: 33.4rem;
		padding: 0 0;
		
		.b2c-input {				
					
			input {
				border-radius: 0 !important;
				border: 1px solid black;
				box-shadow: 0 0 !important;
				outline: none !important;
			}
				
		}
		
		.b2c-input-group-right {
			border-right: 0;
		}

		.b2c-input-group-text {
			border: 1px solid;
			border-radius: 0 !important;
		}
		
		a {
			color: #000;
		}
		
		a:hover {
			text-decoration-thickness: 2px;
		}
		
		.b2c-btn-signin, .b2c-btn-create {
			border-color: #316F2E;
			color: #316F2E;
			border-radius: 0;
			padding: .75rem 2.85rem;
		}
		
		.b2c-btn-signin:hover, .b2c-btn-create:hover {
			color: #fff;
		}
		
		.b2c-sendemail,.b2c-privacy {
			
			input[type="checkbox"] {
				height: 18px;
				width: 18px;
				margin-right: 10px;	
			}
			
		}
			
		.b2c-pwd-rule {
			font-size: 1rem;
		}
		
	}
	
	@include media-breakpoint-up(xl) {

		.b2c-login-content, .b2c-register-content {
		
			padding: 0 1.5rem;
		
		}

	}
	
}

_show_be_error {
	text-align: center !important;
	margin-top: 1.2rem;
	color: #FF0000;
}

/* Preview Extensions*/
.b2c-folder-foreground {
  pointer-events: auto;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  overflow: visible;
  max-width: 100%;
  max-height: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 1;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}

.b2c-folder-foreground {

	.prvdirectory { background: url(icon_folder.png) left no-repeat; }
		
	.prvext_generic { background: url(genericfile.svg) left no-repeat; }
		
	.prvext_msg { background: url(email.svg) left no-repeat; }
		
	.prvext_bat { background: url(code.svg) left no-repeat; }
	.prvext_cmd { background: url(code.svg) left no-repeat; }
	.prvext_css { background: url(code.svg) left no-repeat; }
	.prvext_html { background: url(code.svg) left no-repeat; }
	.prvext_htm { background: url(code.svg) left no-repeat; }
	.prvext_java { background: url(code.svg) left no-repeat; }
	.prvext_php { background: url(code.svg) left no-repeat; }
	.prvext_js { background: url(code.svg) left no-repeat; }
	.prvext_pl { background: url(code.svg) left no-repeat; }
	.prvext_py { background: url(code.svg) left no-repeat; }
	.prvext_rb { background: url(code.svg) left no-repeat; }
	.prvext_rbx { background: url(code.svg) left no-repeat; }
	.prvext_rhtml { background: url(code.svg) left no-repeat; }
	.prvext_ruby { background: url(code.svg) left no-repeat; }
	.prvext_afp { background: url(code.svg) left no-repeat; }
	.prvext_afpa { background: url(code.svg) left no-repeat; }
	.prvext_asp { background: url(code.svg) left no-repeat; }
	.prvext_aspx { background: url(code.svg) left no-repeat; }
	.prvext_c { background: url(code.svg) left no-repeat; }
	.prvext_cfm { background: url(code.svg) left no-repeat; }
	.prvext_cgi { background: url(code.svg) left no-repeat; }
	.prvext_cpp { background: url(code.svg) left no-repeat; }
	.prvext_h { background: url(code.svg) left no-repeat; }
	.prvext_lasso { background: url(code.svg) left no-repeat; }
	.prvext_vb { background: url(code.svg) left no-repeat; }
	.prvext_xml { background: url(code.svg) left no-repeat; }
	.prvext_json { background: url(code.svg) left no-repeat; }
	
	.prvext_zip { background: url(zip.svg) left no-repeat; }
	.prvext_7z  { background: url(zip.svg) left no-repeat; }
	.prvext_rar { background: url(zip.svg) left no-repeat; }
	.prvext_jar { background: url(zip.svg) left no-repeat; }
	.prvext_gz  { background: url(zip.svg) left no-repeat; }
	.prvext_cab { background: url(zip.svg) left no-repeat; }
	.prvext_arv { background: url(zip.svg) left no-repeat; }
	.prvext_iso { background: url(zip.svg) left no-repeat; }
	
	.prvext_odt { background: url(docx.svg) left no-repeat; }
	.prvext_doc { background: url(docx.svg) left no-repeat; }
	.prvext_docx { background: url(docx.svg) left no-repeat; }
	
	.prvext_ppt { background: url(pptx.svg) left no-repeat; }
	.prvext_pptx{ background: url(pptx.svg) left no-repeat; }
	.prvext_pps { background: url(pptx.svg) left no-repeat; }
	.prvext_ppsx{ background: url(pptx.svg) left no-repeat; }
	
	.prvext_txt { background: url(txt.svg) left no-repeat; }
	
	.prvext_rtf { background: url(rtf.svg) left no-repeat; }
	
	.prvext_xls { background: url(xlsx.svg) left no-repeat; }
	.prvext_xlsx{ background: url(xlsx.svg) left no-repeat; }
	.prvext_csv { background: url(xlsx.svg) left no-repeat; }
	.prvext_ods { background: url(xlsx.svg) left no-repeat; }
	
	.prvext_pdf { background: url(pdf.svg) left no-repeat; }
	.prvext_p7m { background: url(pdf.svg) left no-repeat; }
	
	.prvext_bmp { background: url(photo.svg) left no-repeat; }
	.prvext_pcx { background: url(photo.svg) left no-repeat; }
	.prvext_tif { background: url(photo.svg) left no-repeat; }
	.prvext_tiff{ background: url(photo.svg) left no-repeat; }
	.prvext_gif { background: url(photo.svg) left no-repeat; }
	.prvext_jpg { background: url(photo.svg) left no-repeat; }
	.prvext_webp { background: url(photo.svg) left no-repeat; }
	.prvext_jpeg{ background: url(photo.svg) left no-repeat; }
	.prvext_png { background: url(photo.svg) left no-repeat; }
	.prvext_ico { background: url(photo.svg) left no-repeat; }
	.prvext_svg { background: url(photo.svg) left no-repeat; }
	
	.prvext_mp3 { background: url(audio.svg) left no-repeat; }
	.prvext_m4p { background: url(audio.svg) left no-repeat; }
	.prvext_ogg { background: url(audio.svg) left no-repeat; }
	.prvext_wav { background: url(audio.svg) left no-repeat; }
	.prvext_mp4 { background: url(audio.svg) left no-repeat; }
	.prvext_mid { background: url(audio.svg) left no-repeat; }
	.prvext_midi{ background: url(audio.svg) left no-repeat; }
	
	.prvext_3gp { background: url(video.svg) left no-repeat; }
	.prvext_avi { background: url(video.svg) left no-repeat; }
	.prvext_mov { background: url(video.svg) left no-repeat; }
	.prvext_mpg { background: url(video.svg) left no-repeat; }
	.prvext_mpeg{ background: url(video.svg) left no-repeat; }
	.prvext_swf { background: url(video.svg) left no-repeat; }
	.prvext_wmv { background: url(video.svg) left no-repeat; }
	.prvext_flv { background: url(video.svg) left no-repeat; }
	.prvext_f4v { background: url(video.svg) left no-repeat; }
	
}